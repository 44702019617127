import React, { Component } from "react";
import { Layout } from "antd";
import { MainMenu } from "webtek-components";
import { connect } from "react-redux";
import { logoPath, appName } from "../../config/_general";
import "./style.less";
import { layoutActions } from "../../_actions";
const { Sider } = Layout;
const Menu = MainMenu.Main;

class LeftMenu extends Component {
  onTitleClick = a => {
    let openKeys = null;
    if (this.props.openKeys.includes(a.key)) {
      openKeys = this.props.openKeys.filter(key => {
        return key !== a.key;
      });
    } else {
      openKeys = this.props.openKeys.concat(a.key);
    }
    if (openKeys) {
      this.props.setOpenKeys(openKeys);
    }
  };
  onCollapse = collapsed => {
    this.props.toggleSiderCollapse();
  };
  render = () => {
    return (
      <Sider
        breakpoint="lg"
        collapsible
        className="siderWebtek"
        collapsed={!this.props.isSiderOpened}
        onCollapse={this.onCollapse}
      >
        <div className="logo">
          <img src={logoPath} alt={appName} />
        </div>
        <Menu
          className="siderMenu"
          openKeys={this.props.openKeys}
          onTitleClick={this.onTitleClick}
          selectedKeys={[this.props.pathname]}
          modules={this.props.modules}
        />
      </Sider>
    );
  };
}
const mapStateToProps = ({ authentication, router, layout }) => ({
  modules: authentication.user.modules,
  pathname: router.location.pathname,
  openKeys: layout.openKeys,
  isSiderOpened: layout.isSiderOpened
});
const mapDispatchToProps = dispatch => ({
  setOpenKeys: openKeys => dispatch(layoutActions.setOpenKeys(openKeys)),
  toggleSiderCollapse: () => dispatch(layoutActions.toggleSiderCollapse())
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LeftMenu);
