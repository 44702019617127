import React, { Component } from "react";
import { Table, Button, Form, Popconfirm, InputNumber } from "antd";
import ComponentInRawMaterialForm from "../forms/ComponentinRawMaterialForm";

const EditableContext = React.createContext();

const EditableRow = ({ form, index, ...props }) => (
  <EditableContext.Provider value={form}>
    <tr {...props} />
  </EditableContext.Provider>
);

const EditableFormRow = Form.create()(EditableRow);

class EditableCell extends React.Component {
  state = {
    editing: false
  };

  toggleEdit = () => {
    const editing = !this.state.editing;
    this.setState({ editing }, () => {
      if (editing) {
        this.input.focus();
      }
    });
  };

  save = e => {
    const { record, handleSave } = this.props;
    this.form.validateFields((error, values) => {
      if (error && error[e.currentTarget.id]) {
        return;
      }
      this.toggleEdit();
      handleSave({ ...record, ...values });
    });
  };

  render() {
    const { editing } = this.state;
    const {
      editable,
      dataIndex,
      title,
      record,
      index,
      handleSave,
      ...restProps
    } = this.props;
    return (
      <td {...restProps}>
        {editable ? (
          <EditableContext.Consumer>
            {form => {
              this.form = form;
              return editing ? (
                <Form.Item style={{ margin: 0 }}>
                  {form.getFieldDecorator(dataIndex, {
                    rules: [
                      {
                        required: true,
                        message: "Devi inserire un valore compreso fra 1 e 100",
                        min: 1,
                        max: 100,
                        type: "number"
                      }
                    ],
                    initialValue: record[dataIndex]
                  })(
                    <InputNumber
                      ref={node => (this.input = node)}
                      onPressEnter={this.save}
                      onBlur={this.save}
                      min={1}
                      max={100}
                      formatter={value => `${value}%`}
                      parser={value => value.replace("%", "")}
                    />
                  )}
                </Form.Item>
              ) : (
                <div
                  className="editable-cell-value-wrap"
                  style={{ paddingRight: 24 }}
                  onClick={this.toggleEdit}
                >
                  {restProps.children}
                </div>
              );
            }}
          </EditableContext.Consumer>
        ) : (
          restProps.children
        )}
      </td>
    );
  }
}

class ComponentsAssociation extends Component {
  state = { visible: false };

  showModal = () => {
    this.setState({
      visible: true
    });
  };

  handleCreate = e => {
    const form = this.formRef.props.form;
    const { fields } = this.props;
    form.validateFields((err, values) => {
      if (err) {
        return;
      }
      fields.push({
        key: values.component.key,
        component: values.component.key,
        percentage: values.percentage,
        name: values.component.label
      });
      form.resetFields();
      this.setState({ visible: false });
    });
  };

  saveFormRef = formRef => {
    this.formRef = formRef;
  };

  handleCancel = e => {
    this.setState({
      visible: false
    });
  };

  retrieveColumns() {
    const { fields } = this.props;
    return [
      {
        title: "Componente",
        key: "component",
        dataIndex: "component"
      },
      {
        title: "Percentuale",
        key: "percentage",
        dataIndex: "percentage",
        render: value => value + " %",
        editable: true,
        onCell: record => ({
          record,
          editable: true,
          dataIndex: "percentage",
          title: "Percentuale",
          handleSave: this.handleSave
        })
      },
      {
        title: "Azioni",
        key: "action",
        render: (text, record) => (
          <div>
            <Popconfirm
              title="Sei sicuro?"
              onConfirm={() => fields.remove(record.index)}
            >
              <Button icon="delete">Elimina</Button>
            </Popconfirm>
          </div>
        )
      }
    ];
  }

  retrieveDataSource = () => {
    const { fields } = this.props;
    return fields.length
      ? fields.map((name, index, values) => {
          var current = values.get(index);

          return {
            key: name,
            index: index,
            component: current.component.name
              ? current.component.name
              : current.name,
            percentage: current.percentage
          };
        })
      : [];
  };

  handleSave = ({ index, key, component, percentage }) => {
    const { fields } = this.props;
    const oldData = fields.get(index);
    fields.splice(index, 1, {
      ...oldData,
      percentage: percentage
    });
  };

  render() {
    const { fields, meta, children, hasFeedback, label, ...rest } = this.props;
    const hasError = meta.error && meta.invalid;
    const components = {
      body: {
        row: EditableFormRow,
        cell: EditableCell
      }
    };
    return (
      <Form.Item
        colon={false}
        validateStatus={hasError ? "error" : "success"}
        hasFeedback={hasFeedback && hasError}
        help={hasError && meta.error}
      >
        <div className="associationsTableMenu">
          <Button onClick={this.showModal}>Aggiungi componente</Button>
        </div>
        <ComponentInRawMaterialForm
          wrappedComponentRef={this.saveFormRef}
          visible={this.state.visible}
          onCancel={this.handleCancel}
          onCreate={this.handleCreate}
        />
        <Table
          components={components}
          rowClassName={() => "editable-row"}
          columns={this.retrieveColumns()}
          {...rest}
          // onChange={input.onChange}
          // onDragStart={input.onDragStart}
          // onDrop={input.onDrop}
          // onFocus={input.onFocus}
          dataSource={this.retrieveDataSource()}
          // value={input.value}
          // children={children}
        />
      </Form.Item>
    );
  }
}

export default ComponentsAssociation;
