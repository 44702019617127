import React, { Component } from "react";
import { connect } from "react-redux";
import { Field, reduxForm, formValueSelector } from "redux-form";
import PropTypes from "prop-types";
import { Form, Row, Col, Card } from "antd";
import { renderField, CountrySelectField } from "../../_components/fields";
import { FormButtonsContainer } from "../../_layouts";

const customerType = {
  privato: 1,
  azienda: 2
};

class FormTek extends Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    error: PropTypes.string
  };
  render() {
    const {
      handleSubmit,
      pristine,
      reset,
      submitting,
      handleDelete,
      extraButtons,
      type
    } = this.props;

    return (
      <Form layout="vertical" onSubmit={handleSubmit}>
        <Card title="Dati Anagrafici principali">
          <Row>
            <Col xs={24} sm={12}>
              <Field
                component={renderField}
                label="Codice"
                placeholder="COD001"
                name="code"
                type="text"
                required={true}
              />
            </Col>
            <Col xs={24}>
              <Field
                component={renderField}
                label="Tipo"
                name="type"
                type="radioButton"
                choices={[
                  { value: customerType.privato, label: "Privato" },
                  { value: customerType.azienda, label: "Azienda" }
                ]}
                placeholder=""
                required={true}
                normalize={v => parseFloat(v)}
              />
            </Col>
          </Row>

          {type === customerType.privato && (
            <Row gutter={16}>
              <Col xs={24} sm={12}>
                <Field
                  component={renderField}
                  label="Nome"
                  name="name"
                  type="text"
                  placeholder="Mario"
                />
              </Col>
              <Col xs={24} sm={12}>
                <Field
                  component={renderField}
                  label="Cognome"
                  name="surname"
                  type="text"
                  placeholder="Rossi"
                />
              </Col>
              <Col xs={24} sm={12}>
                <Field
                  component={renderField}
                  label="Codice Fiscale"
                  name="codiceFiscale"
                  type="text"
                  placeholder="RSSMRA80A01I829Y"
                />
              </Col>
            </Row>
          )}
          {type === customerType.azienda && (
            <div>
              <Row gutter={16}>
                <Col xs={24} sm={12}>
                  <Field
                    component={renderField}
                    label="Ragione Sociale"
                    name="ragioneSociale"
                    type="text"
                    placeholder="Webtek S.p.a."
                  />
                </Col>
              </Row>
              <Row gutter={16}>
                <Col xs={24} sm={12}>
                  <Field
                    component={renderField}
                    label="Partita Iva"
                    name="partitaIva"
                    type="text"
                    placeholder="00952780146"
                  />
                </Col>
                <Col xs={24} sm={12}>
                  <Field
                    component={renderField}
                    label="Codice Destinatario"
                    name="codiceDestinatario"
                    type="text"
                    placeholder="1234567"
                  />
                </Col>
                <Col xs={24} sm={12}>
                  <Field
                    component={renderField}
                    label="Pec"
                    name="pec"
                    type="text"
                    placeholder="pec@pec.it"
                  />
                </Col>
              </Row>
            </div>
          )}
        </Card>
        <Card title="Altri dati">
          <Row gutter={16}>
            <Col xs={24} sm={8}>
              <Field
                component={renderField}
                label="Telefono"
                name="telephone"
                type="text"
                placeholder="0342-393000"
              />
            </Col>
            <Col xs={24} sm={8}>
              <Field
                component={renderField}
                label="Fax"
                name="fax"
                type="text"
                placeholder="0342-393000"
              />
            </Col>

            <Col xs={24} sm={8}>
              <Field
                component={renderField}
                label="Cellulare"
                name="mobile"
                type="text"
                placeholder="3350011223"
              />
            </Col>
          </Row>
          <Field
            component={renderField}
            label="Sito Web"
            name="website"
            type="text"
            placeholder="https://www.webtek.it"
          />
          <Field
            component={renderField}
            label="Email"
            name="email"
            type="text"
            placeholder="email@gmail.com"
          />
          <Field
            component={renderField}
            label="Note"
            name="notes"
            type="longtext"
            placeholder="Note"
          />
        </Card>

        <Card title="Indirizzo">
          <Row gutter={16}>
            <Col sm={12}>
              <Field
                component={renderField}
                label="Indirizzo"
                name="address.address"
                type="text"
                placeholder="Via Lozzoni 24"
              />
            </Col>
            <Col sm={12}>
              <Field
                component={renderField}
                label="Cap"
                name="address.cap"
                type="text"
                placeholder="23100"
              />
            </Col>
            <Col sm={12}>
              <Field
                component={renderField}
                label="Città"
                name="address.city"
                type="text"
                placeholder="Sondrio"
              />
            </Col>
            <Col sm={12}>
              <Field
                component={renderField}
                label="Provincia"
                name="address.province"
                type="text"
                placeholder="Sondrio"
              />
            </Col>
            <Col sm={12}>
              <Field
                component={CountrySelectField}
                label="Nazione"
                name="address.country"
                type="text"
                placeholder="IT"
              />
            </Col>
          </Row>
        </Card>

        <FormButtonsContainer
          extraButtons={extraButtons}
          disabled={pristine || submitting}
          handleDelete={handleDelete}
          reset={reset}
        />
      </Form>
    );
  }
}

const selector = formValueSelector("customer");
FormTek = connect(state => {
  const type = selector(state, "type");
  return {
    type: type
  };
})(FormTek);

export default reduxForm({
  form: "customer",
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  initialValues: {
    type: 1
  }
})(FormTek);
