import React, { Component } from "react";
import { connect } from "react-redux";
import { Table, Tooltip, Icon } from "antd";
import { CurrencyFormatter } from "../../_services/field_formatters";
import { update } from "../../actions/productvariant/update";
import {
  EditableFormRow,
  EditableCell
} from "../../_components/table/EditableTable";
import { CurrencyInput, QuantityInput } from "../../input";
import { Link } from "react-router-dom";

class ProductVariantTable extends Component {
  retrieveColumns = () => {
    return [
      // {
      //   title: "id",
      //   key: "id",
      //   dataIndex: "id"
      // },
      {
        title: "Codice Variante",
        key: "code",
        dataIndex: "code"
      },
      {
        title: "Nome",
        key: "name",
        dataIndex: "name",
        editable: true,
        onCell: record => ({
          record,
          editable: true,
          dataIndex: "name",
          title: "Nome",
          handleSave: this.handleSave
        })
      },
      {
        title: "Costo",
        key: "cost",
        dataIndex: "cost",
        render: CurrencyFormatter
      },
      {
        title: "Prezzo",
        key: "price",
        dataIndex: "price",
        onCell: record => ({
          record,
          editable: true,
          dataIndex: "price",
          title: "Prezzo",
          editComponent: CurrencyInput,
          handleSave: this.handleSave
        }),
        render: CurrencyFormatter
      },
      {
        title: "Quantità",
        key: "quantity",
        dataIndex: "quantity",
        onCell: record => ({
          record,
          editable: true,
          dataIndex: "quantity",
          title: "Quantità",
          editComponent: QuantityInput,
          handleSave: this.handleSave
        })
      },
      {
        title: "Azioni",
        key: "action",
        fixed: "right",
        width: 125,
        render: (text, record) => (
          <div className="table__actionsMenu">
            <Tooltip title="Visualizza i movimenti di magazzino">
              <Link to={`stock_movements/${encodeURIComponent(record["@id"])}`}>
                <Icon type="stock" />
              </Link>
            </Tooltip>
          </div>
        )
      }
    ];
  };
  retrieveDataSource = () => {
    const { dataSource } = this.props;
    return dataSource.map(value => ({
      key: value["@id"],
      "@id": value["@id"],
      id: value["@id"],
      code: value.code,
      name: value.name,
      cost: value.cost,
      price: value.price,
      quantity: value.quantity
    }));
  };

  handleSave = values => {
    const { update } = this.props;
    update(values, {
      name: values.name,
      quantity: values.quantity,
      price: values.price
    }).then(this.props.reload);
  };

  //   reload = () => {
  //     this.props.list(
  //       this.props.match.params.page &&
  //         decodeURIComponent(this.props.match.params.page)
  //     );
  //   };

  render() {
    const components = {
      body: {
        row: EditableFormRow,
        cell: EditableCell
      }
    };
    return (
      <div>
        <Table
          components={components}
          pagination={false}
          rowClassName={() => "editable-row"}
          columns={this.retrieveColumns()}
          dataSource={this.retrieveDataSource()}
        />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    updated: state.productvariant.updated,
    updateError: state.product.update.updateError,
    updateLoading: state.product.update.updateLoading
  };
};

const mapDispatchToProps = dispatch => ({
  update: (item, values) => dispatch(update(item, values))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductVariantTable);
