import React from "react";
import { connect } from "react-redux";
import { LoginForm } from "webtek-components";
import { Redirect } from "react-router-dom";
import { userActions } from "../../_actions";
import { logoPath2 } from "../../config/_general";
import "./style.less";

export const LoginPage = ({
  startLogin,
  isAuthenticated,
  error,
  loggingIn,
  referrer = "/"
}) => {
  return isAuthenticated ? (
    <Redirect to={referrer} />
  ) : (
    <div className="loginPage">
      <LoginForm
        startLogin={startLogin}
        error={error}
        logo={logoPath2}
        loading={loggingIn}
      />
    </div>
  );
};

const mapDispatchToProps = dispatch => ({
  startLogin: (username, password) =>
    dispatch(userActions.login(username, password))
});

const mapStateToProps = ({ authentication, alert, router }) => ({
  isAuthenticated: !!authentication.loggedIn,
  error: alert.message,
  loggingIn: authentication.loggingIn,
  referrer: router.location.state ? router.location.state.referrer : "/"
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LoginPage);
