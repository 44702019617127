import CustomerRoutes from "../routes/customer";
import ColorRoutes from "../routes/color";
import ComponentRoutes from "../routes/component";
import RawMaterialRoutes from "../routes/rawmaterial";
import RawMaterialTypeRoutes from "../routes/rawmaterialtype";
import AccessoryRoutes from "../routes/accessory";
import SemifinishedRoutes from "../routes/semifinished";
import ProductsRoutes from "../routes/product";
import BudgetRoutes from "../routes/budget";
import CommissionRoutes from "../routes/commission";
import DashboardRoutes from "../_components/dashboard/routes";

export default [
  CustomerRoutes,
  ColorRoutes,
  ComponentRoutes,
  RawMaterialRoutes,
  RawMaterialTypeRoutes,
  AccessoryRoutes,
  SemifinishedRoutes,
  ProductsRoutes,
  BudgetRoutes,
  CommissionRoutes,
  DashboardRoutes
];
