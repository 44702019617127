import React from "react";
import { Form, Input, Radio, Select, Checkbox, InputNumber } from "antd";
import RawMaterialTypeSelect from "../../_components/select/RawMaterialTypeSelect";
import ColorSelect from "../../_components/select/ColorSelect";
import { ColorPicker } from "webtek-components";
import UnitOfMeasureSelect from "../select/UnitOfMeasureSelect";
import SemifinishedSelect from "../select/SemifinishedSelect";
import AccessorySelect from "../select/AccessorySelect";
import CountrySelect from "../select/CountrySelect";
import CustomerSelect from "../select/CustomerSelect";

const makeField = Component => ({
  input,
  meta,
  children,
  hasFeedback,
  label,
  help,
  ...rest
}) => {
  const hasError = meta.touched && meta.invalid;
  if (hasError) {
    help = hasError && meta.error;
  }
  return (
    <Form.Item
      colon={false}
      label={label ? label : input.name}
      validateStatus={hasError ? "error" : "success"}
      hasFeedback={hasFeedback && hasError}
      help={help}
    >
      <Component
        {...rest}
        onChange={input.onChange}
        onDragStart={input.onDragStart}
        onDrop={input.onDrop}
        onFocus={input.onFocus}
        value={input.value}
        children={children}
      />
    </Form.Item>
  );
};

export const InputField = makeField(Input);
export const RadioGroupField = makeField(Radio.Group);
export const SelectField = makeField(Select);
export const CheckboxField = makeField(Checkbox);
export const TextAreaField = makeField(Input.TextArea);
export const InputNumberField = makeField(InputNumber);
export const RawMaterialTypeSelectField = makeField(RawMaterialTypeSelect);
export const ColorSelectField = makeField(ColorSelect);
export const ColorPickerField = makeField(ColorPicker);
export const UnitOfMeasureSelectField = makeField(UnitOfMeasureSelect);
export const SemifinishedSelectField = makeField(SemifinishedSelect);
export const AccessorySelectField = makeField(AccessorySelect);
export const CountrySelectField = makeField(CountrySelect);
export const CustomerSelectField = makeField(CustomerSelect);

export const renderField = data => {
  switch (data.type) {
    case "text":
      return <InputField {...data} />;
    case "longtext":
      return <TextAreaField {...data} />;
    case "currency":
      return (
        <InputNumberField type="text" className="input--currency" {...data} />
      );
    case "number":
      return <InputNumberField {...data} type="text" />;
    case "checkbox":
      return <CheckboxField {...data} />;
    case "select":
      var options = data.options.map(({ value, label }) => (
        <Select.Option key={value} value={value}>
          {label}
        </Select.Option>
      ));
      return <SelectField {...data}>{options}</SelectField>;
    case "radioButton":
      var choicesList = data.choices.map(({ value, label }) => (
        <Radio.Button key={value} value={value}>
          {label}
        </Radio.Button>
      ));
      return (
        <div>
          <RadioGroupField {...data} buttonStyle="solid">
            {choicesList}
          </RadioGroupField>
        </div>
      );
    default:
      return this.renderField(data);
  }
};
