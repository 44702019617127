// import config from "config";
// import { authHeader } from "../_helpers";
import { fetch } from "../utils/dataAccess";

//TODO questa lista di moduli e permessi sarà gestita dalla api e verrà inviata al momento dell'autenticazione
const modules = {
  modules: [
    {
      name: "customers",
      route: "/customers/",
      label: "Clienti",
      icon: "user",
      submodules: [
        {
          name: "customers",
          route: "/customers/",
          label: "Vedi tutte",
          icon: "table"
        },
        {
          name: "customers",
          route: "/customers/create",
          label: "Crea nuovo",
          icon: "plus"
        }
      ],
      permissions: {
        create: true,
        edit: true,
        delete: true,
        show: true
      }
    },
    {
      name: "colors",
      route: "/colors/",
      label: "Colori",
      icon: "bg-colors",
      submodules: [
        {
          name: "colors",
          route: "/colors/",
          label: "Vedi tutti",
          icon: "table"
        },
        {
          name: "colors",
          route: "/colors/create",
          label: "Crea nuovo",
          icon: "plus"
        }
      ],
      permissions: {
        create: true,
        edit: true,
        delete: true,
        show: true
      }
    },
    {
      name: "components",
      route: "/components/",
      label: "Componenti",
      icon: "block",
      submodules: [
        {
          name: "components",
          route: "/components/",
          label: "Vedi tutti",
          icon: "table"
        },
        {
          name: "components",
          route: "/components/create",
          label: "Crea nuovo",
          icon: "plus"
        }
      ],
      permissions: {
        create: true,
        edit: true,
        delete: true,
        show: true
      }
    },
    {
      name: "raw_material_types",
      route: "/raw_material_types/",
      label: "Tipologie di Materie Prime",
      icon: "block",
      submodules: [
        {
          name: "raw_material_types",
          route: "/raw_material_types/",
          label: "Vedi tutti",
          icon: "table"
        },
        {
          name: "raw_material_types",
          route: "/raw_material_types/create",
          label: "Crea nuovo",
          icon: "plus"
        }
      ],
      permissions: {
        create: true,
        edit: true,
        delete: true,
        show: true
      }
    },
    {
      name: "accessories",
      route: "/accessories/",
      label: "Accessori",
      icon: "block",
      submodules: [
        {
          name: "accessories",
          route: "/accessories/",
          label: "Vedi tutti",
          icon: "table"
        },
        {
          name: "accessories",
          route: "/accessories/create",
          label: "Crea nuovo",
          icon: "plus"
        }
      ],
      permissions: {
        create: true,
        edit: true,
        delete: true,
        show: true,
        stock_movements: true
      }
    },
    {
      name: "raw_materials",
      route: "/raw_materials/",
      label: "Materie Prime",
      icon: "block",
      submodules: [
        {
          name: "raw_materials",
          route: "/raw_materials/",
          label: "Vedi tutti",
          icon: "table"
        },
        {
          name: "raw_materials",
          route: "/raw_materials/create",
          label: "Crea nuovo",
          icon: "plus"
        }
      ],
      permissions: {
        create: true,
        edit: true,
        delete: true,
        show: true,
        stock_movements: true
      }
    },
    {
      name: "semifinisheds",
      route: "/semifinisheds/",
      label: "Semilavorati",
      icon: "block",
      submodules: [
        {
          name: "semifinisheds",
          route: "/semifinisheds/",
          label: "Vedi tutti",
          icon: "table"
        },
        {
          name: "semifinisheds",
          route: "/semifinisheds/create",
          label: "Crea nuovo",
          icon: "plus"
        }
      ],
      permissions: {
        create: true,
        edit: true,
        delete: true,
        show: true
      }
    },
    {
      name: "products",
      route: "/products/",
      label: "Prodotti",
      icon: "block",
      submodules: [
        {
          name: "products",
          route: "/products/",
          label: "Vedi tutti",
          icon: "table"
        },
        {
          name: "products",
          route: "/products/create",
          label: "Crea nuovo",
          icon: "plus"
        }
      ],
      permissions: {
        create: true,
        edit: true,
        delete: true,
        show: true,
        stock_movements: true
      }
    },
    {
      name: "budgets",
      route: "/budgets/",
      label: "Budget",
      icon: "block",
      submodules: [
        {
          name: "budgets",
          route: "/budgets/",
          label: "Vedi tutti",
          icon: "table"
        },
        {
          name: "budgets",
          route: "/budgets/create",
          label: "Crea nuovo",
          icon: "plus"
        }
      ],
      permissions: {
        create: true,
        edit: true,
        delete: true,
        show: true
      }
    },
    // {
    //   name: "commissions",
    //   route: "/commissions/",
    //   label: "Commesse",
    //   icon: "block",
    //   submodules: [
    //     {
    //       name: "commissions",
    //       route: "/commissions/",
    //       label: "Vedi tutti",
    //       icon: "table"
    //     },
    //     {
    //       name: "commissions",
    //       route: "/commissions/create",
    //       label: "Crea nuova",
    //       icon: "plus"
    //     }
    //   ],
    //   permissions: {
    //     create: true,
    //     edit: true,
    //     delete: true,
    //     show: true
    //   }
    // }
  ]
};

const login = (username, password) => {
  return fetch(
    "/login_check",
    {
      method: "POST",
      body: JSON.stringify({
        username: username,
        password: password
      })
    },
    true
  ).then(response => {
    switch (response.status) {
      case 200:
        return response.json().then(result => {
          let token = result.token;
          let user = {
            username: "Manifattura Bernina",
            token: token,
            refresh_token: result.refresh_token,
            ...modules
          };
          localStorage.setItem("user", JSON.stringify(user));
          return user;
        });
      case 401:
        logout();
        return Promise.reject();
      // location.reload(true);
      case 403:
        return Promise.reject("Username o password errata");
      case 404:
        return Promise.reject("Utente non trovato");
      default:
        return Promise.reject(
          "Errore di sistema, contattare l'assistenza tecnica"
        );
    }
  });
};

export const userService = {
  login,
  logout
  //   getAll
};

// function login(username, password) {
//   const requestOptions = {
//     method: "POST",
//     headers: { "Content-Type": "application/json" },
//     body: JSON.stringify({ username, password })
//   };

//   return fetch(`${config.apiUrl}/users/authenticate`, requestOptions)
//     .then(handleResponse)
//     .then(user => {
//       // login successful if there's a jwt token in the response
//       if (user.token) {
//         // store user details and jwt token in local storage to keep user logged in between page refreshes
//         localStorage.setItem("user", JSON.stringify(user));
//       }

//       return user;
//     });
// }

function logout() {
  // remove user from local storage to log user out
  localStorage.removeItem("user");
}

// function getAll() {
//   const requestOptions = {
//     method: "GET",
//     headers: authHeader()
//   };

//   return fetch(`${config.apiUrl}/users`, requestOptions).then(handleResponse);
// }

// // function handleResponse(response) {
// //   return response.json().then(data => {
// //     if (!response.ok) {
// //       if (response.status === 401) {
// //         // auto logout if 401 response returned from api
// //         logout();
// //         location.reload(true);
// //       }

// //       const error = (data && data.error) || response.statusText;
// //       return Promise.reject(error);
// //     }

// //     return data;
// //   });
// // }
