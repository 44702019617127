import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { list, reset } from "../../actions/commission/list";
import { TableClientSide } from "webtek-components";
import ListMenu from "../../_layouts/ListMenu";
import ModuleView from "../../_layouts/ModuleView";
import moment from "moment";
import "moment/locale/it";
import { statusTrans } from "./common_functions";
import { Progress } from "antd";

class List extends Component {
  static propTypes = {
    retrieved: PropTypes.object,
    loading: PropTypes.bool.isRequired,
    error: PropTypes.string,
    eventSource: PropTypes.instanceOf(EventSource),
    deletedItem: PropTypes.object,
    list: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired
  };

  componentDidMount() {
    this.props.list(
      this.props.match.params.page &&
        decodeURIComponent(this.props.match.params.page)
    );
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.match.params.page !== nextProps.match.params.page)
      nextProps.list(
        nextProps.match.params.page &&
          decodeURIComponent(nextProps.match.params.page)
      );
  }

  componentWillUnmount() {
    this.props.reset(this.props.eventSource);
  }

  retrieveNotifications = () => {
    var notifications = [];
    if (this.props.deletedItem) {
      notifications.push({
        message: this.props.deletedItem["@id"] + " eliminato."
      });
    }
    return notifications;
  };

  getUniqueCustomersFromResult() {
    if (this.props.retrieved && this.props.retrieved["hydra:member"]) {
      var types = [];
      var foundTypes = [];
      this.props.retrieved["hydra:member"].forEach(item => {
        if (item["customer"]) {
          if (!foundTypes.includes(item["customer"].id)) {
            foundTypes.push(item["customer"].id);
            types.push({
              text: this.formatCustomer(item["customer"]),
              value: this.formatCustomer(item["customer"])
            });
          }
        }
      });
      return types;
    }
    return null;
  }

  getUniqueProductsFromResult() {
    if (this.props.retrieved && this.props.retrieved["hydra:member"]) {
      var types = [];
      var foundTypes = [];
      this.props.retrieved["hydra:member"].forEach(item => {
        if (item["product"]) {
          if (!foundTypes.includes(item["product"]["@id"])) {
            foundTypes.push(item["product"]["@id"]);
            types.push({
              text: item["product"].name,
              value: item["product"].name
            });
          }
        }
      });
      return types;
    }
    return null;
  }

  retrieveColumns() {
    return [
      {
        title: "Data",
        key: "data",
        dataIndex: "data",
        render: value =>
          moment(value)
            .locale("it")
            .format("L LT"),
        filter: null,
        sorter: (a, b) => {
          return moment(a.data).isAfter(b.data);
        }
      },
      {
        title: "Cliente",
        key: "customer",
        dataIndex: "customer",
        filter: {
          type: "custom"
        },
        filters: this.getUniqueCustomersFromResult(),
        onFilter: (value, record) => record.customer.indexOf(value) === 0
      },
      {
        title: "Prodotto",
        key: "productName",
        dataIndex: "productName",
        filter: {
          type: "custom"
        },
        filters: this.getUniqueProductsFromResult(),
        onFilter: (value, record) => record.productName.indexOf(value) === 0
      },
      {
        title: "Stato",
        key: "status",
        dataIndex: "status",
        filter: {
          type: "custom"
        },
        filters: [
          {
            text: "Da lavorare",
            value: "Da lavorare"
          },
          {
            text: "In lavorazione",
            value: "In lavorazione"
          },
          {
            text: "Sospesa",
            value: "Sospesa"
          },
          {
            text: "Eliminata",
            value: "Eliminata"
          },
          {
            text: "Completa",
            value: "Completa"
          }
        ],
        onFilter: (value, record) => record.status.indexOf(value) === 0,
        render: value => {
          return (
            <Progress
              style={{ display: "block", margin: "auto", textAlign: "center" }}
              type="dashboard"
              percent={this.formatStatusProgress(value)}
              status={this.formatStatusProgressStatus(value)}
              width={70}
              format={() => this.formatStatusProgressText(value)}
            />
          );
        }
      }
    ];
  }

  formatStatusProgressStatus = status => {
    switch (status) {
      case "Completa":
        return "success";
      case "Eliminata":
        return "exception";
      case "Da lavorare":
        return "normal";
      default:
        return "active";
    }
  };

  formatStatusProgress = status => {
    switch (status) {
      case "Completa":
      case "Eliminata":
        return 100;
      case "Sospesa":
      case "In lavorazione":
        return 50;
      default:
        return 0;
    }
  };

  formatStatusProgressText = status => {
    if (status === "In lavorazione") status = "In lavor.";
    return <div style={{ fontSize: "12px" }}>{status}</div>;
  };

  formatCustomer = customer => {
    if (!customer) return "";
    if (customer.type === 1) {
      return (
        customer.name + " " + customer.surname + " (" + customer.code + ")"
      );
    }
    return customer.ragioneSociale + " (" + customer.code + ")";
  };

  retrieveDataSource() {
    if (this.props.retrieved && this.props.retrieved["hydra:member"]) {
      return this.props.retrieved["hydra:member"].map(item => {
        return {
          key: item["@id"],
          "@id": item["@id"],
          data: item["data"],
          status: statusTrans(item["status"]),
          customer: this.formatCustomer(item["customer"]),
          // product: item["product"],
          productName: item["product"] ? item["product"].name : null,
          discardIfDeleted: item["discardIfDeleted"],
          notes: item["notes"]
        };
      });
    }
    return [];
  }

  render() {
    const { loading, error } = this.props;
    return (
      <ModuleView
        title="Elenco Commesse"
        subTitle="Visualizza le commesse inserite"
        loading={loading}
        notifications={this.retrieveNotifications()}
        error={error}
      >
        <ListMenu />
        <TableClientSide
          size="middle"
          dataSource={this.retrieveDataSource()}
          columns={this.retrieveColumns()}
          scroll={{ x: 1300 }}
          pagination={{
            defaultPageSize: 7
          }}
        />
      </ModuleView>
    );
  }

  // pagination() {
  //   const view = this.props.retrieved && this.props.retrieved['hydra:view'];
  //   if (!view) return;

  //   const {
  //     'hydra:first': first,
  //     'hydra:previous': previous,
  //     'hydra:next': next,
  //     'hydra:last': last
  //   } = view;

  //   return (
  //     <nav aria-label="Page navigation">
  //       <Link
  //         to="."
  //         className={`btn btn-primary${previous ? '' : ' disabled'}`}
  //       >
  //         <span aria-hidden="true">&lArr;</span> First
  //       </Link>
  //       <Link
  //         to={
  //           !previous || previous === first ? '.' : encodeURIComponent(previous)
  //         }
  //         className={`btn btn-primary${previous ? '' : ' disabled'}`}
  //       >
  //         <span aria-hidden="true">&larr;</span> Previous
  //       </Link>
  //       <Link
  //         to={next ? encodeURIComponent(next) : '#'}
  //         className={`btn btn-primary${next ? '' : ' disabled'}`}
  //       >
  //         Next <span aria-hidden="true">&rarr;</span>
  //       </Link>
  //       <Link
  //         to={last ? encodeURIComponent(last) : '#'}
  //         className={`btn btn-primary${next ? '' : ' disabled'}`}
  //       >
  //         Last <span aria-hidden="true">&rArr;</span>
  //       </Link>
  //     </nav>
  //   );
  // }

  renderLinks = (type, items) => {
    if (Array.isArray(items)) {
      return items.map((item, i) => (
        <div key={i}>{this.renderLinks(type, item)}</div>
      ));
    }

    return (
      <Link to={`../${type}/show/${encodeURIComponent(items)}`}>{items}</Link>
    );
  };
}

const mapStateToProps = state => {
  const { retrieved, loading, error, eventSource } = state.commission.list;
  const deletedItem = state.commission.del.deleted;
  return { retrieved, loading, error, eventSource, deletedItem };
};

const mapDispatchToProps = dispatch => ({
  list: page => dispatch(list(page)),
  reset: eventSource => dispatch(reset(eventSource))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(List);
