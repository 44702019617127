import React, { Component } from "react";
import { Field, reduxForm } from "redux-form";
import PropTypes from "prop-types";
import { Form, Card } from "antd";
import { renderField } from "../../_components/fields";
import { FormButtonsContainer } from "../../_layouts";

class FormTek extends Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    error: PropTypes.string
  };

  render() {
    const {
      handleSubmit,
      pristine,
      reset,
      submitting,
      handleDelete,
      extraButtons,
      renderMenu = true
    } = this.props;

    return (
      <Form layout="vertical" onSubmit={handleSubmit}>
        <Card title="Informazioni">
          <Field
            component={renderField}
            name="name"
            label="Nome"
            type="text"
            placeholder=""
            required={true}
          />
        </Card>
        {renderMenu ? (
          <FormButtonsContainer
            extraButtons={extraButtons}
            disabled={pristine || submitting}
            handleDelete={handleDelete}
            reset={reset}
          />
        ) : null}
      </Form>
    );
  }
}

export default reduxForm({
  form: "component",
  enableReinitialize: true,
  keepDirtyOnReinitialize: true
})(FormTek);
