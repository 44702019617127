import React, { Component } from "react";
import requireAuthentication from "../../_hoc/requireAuthentication";
import { Modal, Alert, Spin } from "antd";

class UpdateModalType extends Component {
  componentDidMount() {
    this.props.retrieve(this.props.iri);
  }

  componentWillUnmount() {
    this.props.reset(this.props.eventSource);
  }

  render() {
    const {
      visible,
      title,
      updated,
      updateLoading,
      retrieved,
      retrieveLoading,
      Form
    } = this.props;
    const item = updated ? updated : retrieved;

    return (
      <Modal
        title={title}
        okText="Salva"
        visible={visible}
        onOk={this.onOk}
        onCancel={this.onCancel}
        confirmLoading={updateLoading}
      >
        {this.generateErrorsAlerts()}
        {retrieveLoading ? (
          <Spin />
        ) : (
          <Form
            onSubmit={this.handleSubmit}
            initialValues={item}
            ref={this.attachNode}
            renderMenu={false}
          />
        )}
      </Modal>
    );
  }

  generateErrorsAlerts = () => {
    const errors = this.retrieveErrors();
    if (errors) {
      return errors.map((error, index) => (
        <Alert
          key={"error_" + index}
          message={error.message}
          description={error.description}
          type="error"
          showIcon
          closable
        />
      ));
    }
    return null;
  };

  onOk = () => {
    this.form.submit();
  };

  onCancel = () => {
    this.form.reset();
    this.props.onCancel();
  };

  attachNode = node => {
    this.form = node;
  };

  handleSubmit = values => {
    const { update, onUpdate } = this.props;
    const item = this.props.updated ? this.props.updated : this.props.retrieved;
    return update(item, values).then(() => {
      this.form.reset();
      onUpdate();
    });
  };

  retrieveErrors = () => {
    var errors = [];
    if (this.props.retrieveError) {
      errors.push({
        message: "Errore durante il recupero dei dati",
        description: this.props.retrieveError
      });
    }
    if (this.props.updateError) {
      errors.push({
        message: "Errore durante l'aggiornamento",
        description: this.props.updateError
      });
    }
    if (this.props.deleteError) {
      errors.push({
        message: "Errore durante l'eliminazione",
        description: this.props.deleteError
      });
    }
    return errors;
  };
}

export default requireAuthentication(UpdateModalType);
