import { createStore, compose, applyMiddleware, combineReducers } from "redux";
import { history } from "../_helpers";
import { connectRouter, routerMiddleware } from "connected-react-router";
import thunk from "redux-thunk";
import combinedReducers from "../reducers";
import { authentication, users, alert, layout } from "../_reducers";
import { reducer as form } from "redux-form";

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const defaultReducers = { 
  "router": connectRouter(history),
  authentication, 
  users, 
  alert, 
  layout,
  form
};

export const store = createStore(
  combineReducers({
    ...defaultReducers,
    ...combinedReducers
  } ),
  composeEnhancers(applyMiddleware(routerMiddleware(history), thunk))
  );