import { ENTRYPOINT } from "../config/entrypoint";
import { SubmissionError } from "redux-form";
// import get from "lodash/get";
import has from "lodash/has";
import { store } from "../_helpers";
import { userConstants } from "../_constants";
// import mapValues from "lodash/mapValues";

const MIME_TYPE = "application/ld+json";

const fieldsArrays = [
  "componentsPercentage",
  "rawMaterialsPercentage",
  "rawMaterials",
  "accessories"
];

const refreshToken = () => {
  var headers = new Headers();
  headers.set("Accept", MIME_TYPE);
  headers.set("Content-Type", MIME_TYPE);
  return new Promise((resolve, reject) => {
    return global
      .fetch(new URL("/api/token/refresh", ENTRYPOINT), {
        method: "POST",
        headers: headers,
        body: JSON.stringify({
          refresh_token: store.getState().authentication.user.refresh_token
        })
      })
      .then(response => {
        response.json().then(content => {
          if (response.status === 401) {
            store.dispatch({
              type: userConstants.REFRESH_TOKEN_FAILURE,
              content
            });
            localStorage.clear();
            alert(
              "SESSIONE SCADUTA, è necessario effettuare nuovamente il login al sistema"
            );
            window.location.reload();
            reject();
            return;
          }
          store.dispatch({
            type: userConstants.REFRESH_TOKEN_SUCCESS,
            content
          });
          resolve();
        });
      });
  });
};

export function fetch(id, options = {}, getOnlyResponse = false) {
  if ("undefined" === typeof options.headers) options.headers = new Headers();
  if (null === options.headers.get("Accept"))
    options.headers.set("Accept", MIME_TYPE);

  if (
    "undefined" !== options.body &&
    !(options.body instanceof FormData) &&
    null === options.headers.get("Content-Type")
  )
    options.headers.set("Content-Type", MIME_TYPE);
  var token = store.getState().authentication.user.token;

  options.headers.set("Authorization", `Bearer ${token}`);

  return global.fetch(new URL(id, ENTRYPOINT), options).then(response => {
    if (response.ok) {
      return response;
    }
    if (getOnlyResponse) return response.json();
    if (response.status === 401) {
      //CAN BE EXPIRED TOKEN, SO I TRY TO GET A NEW TOKEN WITH REFRESHTOKEN
      return new Promise((resolve, reject) => {
        refreshToken().then(response => {
          //TOKEN SUCCESSFULLY RENEWED, I MAKE THE INITIAL CALL AND CATCH EVENTUAL ERRORS
          return fetch(id, options, getOnlyResponse).then(response => {
            resolve(response);
          });
        });
      })
        .then(response => {
          return response;
        })
        .catch(error => {
          throw error;
        });
    }
    return response.json().then(json => {
      const error = json["hydra:description"] || response.statusText;
      if (!json.violations) throw Error(error);
      let errors = { _error: error };
      json.violations.forEach(violation => {
        const propertiesPath = violation.propertyPath.split(".");
        if (propertiesPath.length > 1) {
          if (typeof errors[propertiesPath[0]] === "undefined") {
            errors[propertiesPath[0]] = {};
          }
          errors[propertiesPath[0]][propertiesPath[1]] = violation.message;
        } else {
          if (fieldsArrays.includes(violation.propertyPath)) {
            errors[violation.propertyPath] = { _error: violation.message };
          } else {
            errors[violation.propertyPath] = violation.message;
          }
        }
      });
      throw new SubmissionError(errors);
    });
  });
}

export function mercureSubscribe(url, topics) {
  topics.forEach(topic =>
    url.searchParams.append("topic", new URL(topic, ENTRYPOINT))
  );

  return new EventSource(url.toString());
}

export function normalize(data) {
  if (has(data, "hydra:member")) {
    // Normalize items in collections
    data["hydra:member"] = data["hydra:member"].map(item => normalize(item));

    return data;
  }
  return data;
  // Flatten nested documents
  /* return mapValues(data, value =>
    Array.isArray(value)
      ? value.map(v => get(v, '@id', v))
      : get(value, '@id', value)
  );*/
}

export function extractHubURL(response) {
  const linkHeader = response.headers.get("Link");
  if (!linkHeader) return null;

  const matches = linkHeader.match(
    /<([^>]+)>;\s+rel=(?:mercure|"[^"]*mercure[^"]*")/
  );

  return matches && matches[1] ? new URL(matches[1], ENTRYPOINT) : null;
}
