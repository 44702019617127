import React, { Component } from "react";
import { Field, reduxForm, FieldArray } from "redux-form";
import PropTypes from "prop-types";
import { Form, Card, Input } from "antd";
import { translate } from "../../_translations/semifinished";
import { renderField, ColorSelectField } from "../../_components/fields";
import {
  CurrencyFormatter,
  DecimalNormalizer
} from "../../_services/field_formatters";
import RawMaterialAssociation from "../../_components/associations/RawMaterialAssociation";
import { formValueSelector, getFormInitialValues } from "redux-form"; // ES6
import { connect } from "react-redux";
import { FormButtonsContainer } from "../../_layouts";

class FormTek extends Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    error: PropTypes.string
  };

  calcCostForKg = () => {
    var cost = 0;
    this.props.rawMaterialPercentages.forEach(rawMaterialPercentage => {
      cost += parseFloat(rawMaterialPercentage.costPerKgRelative);
    });
    return (
      "€ " +
      new Intl.NumberFormat("it-IT", {
        maximumFractionDigits: 4
      }).format(cost)
    );
  };

  render() {
    const {
      handleSubmit,
      pristine,
      reset,
      submitting,
      handleDelete,
      extraButtons,
      hasRawMaterialsChanged
    } = this.props;

    return (
      <Form layout="vertical" onSubmit={handleSubmit}>
        <Card title="Informazioni">
          <Field
            component={renderField}
            name="name"
            label={translate("name")}
            type="text"
            placeholder="Semilavorato"
            required={true}
          />
          <Field
            component={ColorSelectField}
            name="color"
            label={translate("color")}
            type="text"
            placeholder=""
          />
          {/* <Field
            component={renderField}
            name="quantity"
            label={translate("quantity")}
            type="text"
            placeholder="100,00"
            format={QuantityFormatter}
            normalize={DecimalNormalizer}
            required={true}
          /> */}
          {/* <Field
            component={renderField}
            name="rawMaterialsPercentage"
            label={translate("rawMaterialsPercentage")}
            type="text"
            placeholder=""
            normalize={v => (v === "" ? [] : v.split(","))}
          /> */}
        </Card>
        <Card title="Composizione interna">
          <FieldArray
            rerenderOnEveryChange={true}
            component={RawMaterialAssociation}
            name="rawMaterialsPercentage"
          />
          {hasRawMaterialsChanged ? (
            <Form.Item colon={false} label="Costo al Kg">
              <Input readOnly value={this.calcCostForKg()} />
            </Form.Item>
          ) : (
            <Field
              component={renderField}
              name="costPerKg"
              label="Costo al Kg"
              help=""
              type="text"
              readOnly
              placeholder="E' necessario inserire la composizione per calcolare il costo al kg"
              format={CurrencyFormatter}
              normalize={DecimalNormalizer}
            />
          )}
        </Card>
        <FormButtonsContainer
          extraButtons={extraButtons}
          disabled={pristine || submitting}
          handleDelete={handleDelete}
          reset={reset}
        />
      </Form>
    );
  }
}

//Controllo se la composizione interna è cambiata e mando in props i valori dei singoli componenti attuali cosi' da poter poi calcolare il costo al kg
const selector = formValueSelector("semifinished");
FormTek = connect(state => {
  const initialValues = getFormInitialValues("semifinished")(state);
  var hasRawMaterialsChanged = false;
  var actualRawMaterialPercentages = [];
  const initialRawMaterialsPercentages = initialValues
    ? initialValues.rawMaterialsPercentage
    : [];
  actualRawMaterialPercentages =
    selector(state, "rawMaterialsPercentage") || [];
  if (
    initialRawMaterialsPercentages.length !==
    actualRawMaterialPercentages.length
  ) {
    hasRawMaterialsChanged = true;
  } else {
    initialRawMaterialsPercentages.forEach((value, index) => {
      if (actualRawMaterialPercentages[index]["@id"] !== value["@id"])
        hasRawMaterialsChanged = true;
      if (
        actualRawMaterialPercentages[index]["percentage"] !==
        value["percentage"]
      )
        hasRawMaterialsChanged = true;
    });
  }

  return {
    rawMaterialPercentages: actualRawMaterialPercentages,
    hasRawMaterialsChanged: hasRawMaterialsChanged
  };
})(FormTek);

export default reduxForm({
  form: "semifinished",
  enableReinitialize: true,
  keepDirtyOnReinitialize: true
})(FormTek);
