import React, { Component } from "react";
import { Table, Form, Icon, Tooltip } from "antd";
import { connect } from "react-redux";
import { getFormSubmitErrors, formValueSelector } from "redux-form";
import { QuantityInput } from "../../input";
const EditableContext = React.createContext();

const EditableRow = ({ form, index, ...props }) => (
  <EditableContext.Provider value={form}>
    <tr {...props} />
  </EditableContext.Provider>
);

const EditableFormRow = Form.create()(EditableRow);

class EditableCell extends React.Component {
  state = {
    editing: false
  };

  toggleEdit = () => {
    const editing = !this.state.editing;
    this.setState({ editing }, () => {
      if (editing) {
        this.input.focus();
      }
    });
  };

  save = e => {
    const { record, handleSave } = this.props;
    this.form.validateFields((error, values) => {
      if (error && error[e.currentTarget.id]) {
        return;
      }
      this.toggleEdit();
      handleSave({ ...record, ...values });
    });
  };

  render() {
    const { editing } = this.state;
    const {
      editable,
      dataIndex,
      title,
      record,
      index,
      handleSave,
      ...restProps
    } = this.props;
    return (
      <td {...restProps}>
        {editable ? (
          <EditableContext.Consumer>
            {form => {
              this.form = form;
              return editing ? (
                <Form.Item style={{ margin: 0 }}>
                  {form.getFieldDecorator(dataIndex, {
                    rules: [
                      {
                        required: true,
                        message: "Devi inserire un valore numerico",
                        min: 0,
                        type: "number"
                      }
                    ],
                    initialValue: record[dataIndex]
                  })(
                    <QuantityInput
                      ref={node => (this.input = node)}
                      onPressEnter={this.save}
                      onBlur={this.save}
                      min={0}
                      // formatter={value => `${value}%`}
                      // parser={value => value.replace("%", "")}
                    />
                  )}
                </Form.Item>
              ) : (
                <div
                  className="editable-cell-value-wrap"
                  style={{ paddingRight: 24 }}
                  onClick={this.toggleEdit}
                >
                  {restProps.children}
                </div>
              );
            }}
          </EditableContext.Consumer>
        ) : (
          restProps.children
        )}
      </td>
    );
  }
}

class RawMaterialsInCommissionAssociation extends Component {
  retrieveColumns() {
    var errors = this.props.submitErrors;
    var columns = [
      {
        title: "Materia Prima",
        key: "rawMaterial.name",
        dataIndex: "rawMaterial.name",
        sorter: {
          type: "alphabetical"
        },
        filter: {
          type: "regexp"
        }
      },
      {
        title: "Quantità richiesta stimata",
        key: "estimatedQuantity",
        dataIndex: "estimatedQuantity",
        sorter: {
          type: "alphabetical"
        },
        filter: {
          type: "regexp"
        },
        render: (value, record) => {
          if (
            typeof errors[record.key] !== "undefined" &&
            typeof errors[record.key]["estimatedQuantity"] !== "undefined"
          ) {
            return (
              <Tooltip title={errors[record.key]["estimatedQuantity"]}>
                <span style={{ cursor: "pointer" }}>
                  {value} g{" "}
                  <Icon
                    theme="filled"
                    type="warning"
                    style={{ color: "red" }}
                  />
                </span>
              </Tooltip>
            );
          }
          if (record.warning) {
            return (
              <Tooltip title={record.warning}>
                <span style={{ cursor: "pointer" }}>
                  {value} g{" "}
                  <Icon
                    theme="filled"
                    type="warning"
                    style={{ color: "#fdc720" }}
                  />
                </span>
              </Tooltip>
            );
          }
          return value + " g";
        }
      }
    ];
    if (this.props.isActualQuantityVisible) {
      var actualQuantityColumn = {
        title: "Quantità utilizzata",
        key: "actualQuantity",
        dataIndex: "actualQuantity",
        sorter: {
          type: "alphabetical"
        },
        filter: {
          type: "regexp"
        },
        render: (value, record) => {
          if (
            typeof errors[record.key] !== "undefined" &&
            errors[record.key]["actualQuantity"] !== "undefined"
          ) {
            return (
              <Tooltip title={errors[record.key]["actualQuantity"]}>
                <span style={{ cursor: "pointer" }}>
                  {value ? { value } + " g" : null}
                  <Icon
                    theme="filled"
                    type="warning"
                    style={{ color: "red" }}
                  />
                </span>
              </Tooltip>
            );
          }
          return value ? value + " g" : null;
        }
      };
      var wastedQuantityColumn = {
        title: "Quantità scartata",
        key: "wastedQuantity",
        dataIndex: "wastedQuantity",
        sorter: {
          type: "alphabetical"
        },
        filter: {
          type: "regexp"
        },
        render: record => (record ? record + " g" : null)
      };
      if (this.props.isActualQuantityEditable) {
        actualQuantityColumn = {
          ...actualQuantityColumn,
          editable: true,
          onCell: record => ({
            record,
            editable: true,
            dataIndex: "actualQuantity",
            title: "Quantità utilizzata",
            handleSave: this.handleSave
          })
        };
        wastedQuantityColumn = {
          ...wastedQuantityColumn,
          editable: true,
          onCell: record => ({
            record,
            editable: true,
            dataIndex: "wastedQuantity",
            title: "Quantità utilizzata",
            handleSave: this.handleWastedQuantitySave
          })
        };
      }
      columns.push(actualQuantityColumn);
      columns.push(wastedQuantityColumn);
    }
    return columns;
  }

  generateWarnings = key => {
    if (typeof this.props.warnings !== "undefined") {
      var warnings = this.props.warnings;
      if (key in warnings) {
        return warnings[key];
      }
      return null;
    }
    return null;
  };

  retrieveDataSource = () => {
    const { fields } = this.props;
    return fields.length
      ? fields.map((name, index, values) => {
          var current = values.get(index);
          return {
            key: name,
            index: index,
            rawMaterial: current.rawMaterial,
            estimatedQuantity: current.estimatedQuantity,
            actualQuantity: current.actualQuantity,
            wastedQuantity: current.wastedQuantity,
            warning: this.generateWarnings(name)
          };
        })
      : [];
  };

  handleSave = ({ index, key, actualQuantity }) => {
    const { fields } = this.props;
    const oldData = fields.get(index);
    fields.splice(index, 1, {
      ...oldData,
      actualQuantity: actualQuantity.toString()
    });
  };

  handleWastedQuantitySave = ({ index, key, wastedQuantity }) => {
    const { fields } = this.props;
    const oldData = fields.get(index);
    fields.splice(index, 1, {
      ...oldData,
      wastedQuantity: wastedQuantity.toString()
    });
  };

  render() {
    const { fields, meta, children, hasFeedback, label, ...rest } = this.props;
    const hasError = meta.error && meta.invalid;
    const components = {
      body: {
        row: EditableFormRow,
        cell: EditableCell
      }
    };
    return (
      <Form.Item
        colon={false}
        validateStatus={hasError ? "error" : "success"}
        hasFeedback={hasFeedback && hasError}
        help={hasError && meta.error}
      >
        <Table
          components={components}
          rowClassName={() => "editable-row"}
          columns={this.retrieveColumns()}
          {...rest}
          // onChange={input.onChange}
          // onDragStart={input.onDragStart}
          // onDrop={input.onDrop}
          // onFocus={input.onFocus}
          dataSource={this.retrieveDataSource()}
          // value={input.value}
          // children={children}
        />
      </Form.Item>
    );
  }
}

const selector = formValueSelector("commission");

export default connect(state => ({
  submitErrors: getFormSubmitErrors("commission")(state),
  warnings: selector(state, "warnings")
}))(RawMaterialsInCommissionAssociation);
