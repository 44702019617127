import React, { Component } from "react";
import { Table, Button, Form, Popconfirm, InputNumber } from "antd";
import RawMaterialInSemifinishedForm from "../forms/RawMaterialInSemifinishedForm";
import { CurrencyFormatter } from "../../_services/field_formatters";
const EditableContext = React.createContext();

const EditableRow = ({ form, index, ...props }) => (
  <EditableContext.Provider value={form}>
    <tr {...props} />
  </EditableContext.Provider>
);

const EditableFormRow = Form.create()(EditableRow);

class EditableCell extends React.Component {
  state = {
    editing: false
  };

  toggleEdit = () => {
    const editing = !this.state.editing;
    this.setState({ editing }, () => {
      if (editing) {
        this.input.focus();
      }
    });
  };

  save = e => {
    const { record, handleSave } = this.props;
    this.form.validateFields((error, values) => {
      if (error && error[e.currentTarget.id]) {
        return;
      }
      this.toggleEdit();
      handleSave({ ...record, ...values });
    });
  };

  render() {
    const { editing } = this.state;
    const {
      editable,
      dataIndex,
      title,
      record,
      index,
      handleSave,
      ...restProps
    } = this.props;
    return (
      <td {...restProps}>
        {editable ? (
          <EditableContext.Consumer>
            {form => {
              this.form = form;
              return editing ? (
                <Form.Item style={{ margin: 0 }}>
                  {form.getFieldDecorator(dataIndex, {
                    rules: [
                      {
                        required: true,
                        message: "Devi inserire un valore compreso fra 1 e 100",
                        min: 1,
                        max: 100,
                        type: "number"
                      }
                    ],
                    initialValue: record[dataIndex]
                  })(
                    <InputNumber
                      ref={node => (this.input = node)}
                      onPressEnter={this.save}
                      onBlur={this.save}
                      min={1}
                      max={100}
                      formatter={value => `${value}%`}
                      parser={value => value.replace("%", "")}
                    />
                  )}
                </Form.Item>
              ) : (
                <div
                  className="editable-cell-value-wrap"
                  style={{ paddingRight: 24 }}
                  onClick={this.toggleEdit}
                >
                  {restProps.children}
                </div>
              );
            }}
          </EditableContext.Consumer>
        ) : (
          restProps.children
        )}
      </td>
    );
  }
}

class RawMaterialAssociation extends Component {
  state = { visible: false };

  retrieveDataSource = () => {
    const { fields } = this.props;
    return fields.length
      ? fields.map((name, index, values) => {
          var current = values.get(index);
          return {
            key: name,
            index: index,
            rawMaterial: current.rawMaterial,
            percentage: current.percentage,
            costPerKgRelative: current.costPerKgRelative || null
          };
        })
      : [];
  };

  showModal = () => {
    this.setState({
      visible: true
    });
  };

  calcRelativeCost = (costPerKg, percentage) => {
    return (Math.round(costPerKg * (percentage / 100) * 10000) / 10000).toFixed(
      4
    );
  };
  handleCreate = e => {
    const form = this.formRef.props.form;
    const { fields } = this.props;
    form.validateFields((err, { rawMaterial, percentage }) => {
      if (err) {
        return;
      }
      const rawMaterialData = JSON.parse(rawMaterial.key);
      const costPerKgRelative = this.calcRelativeCost(
        rawMaterialData.costPerKg,
        percentage
      );
      fields.push({
        key: rawMaterialData.id,
        rawMaterial: rawMaterialData,
        percentage: percentage,
        costPerKgRelative: costPerKgRelative
      });
      form.resetFields();
      this.setState({ visible: false });
    });
  };

  handleSave = ({ index, key, rawmaterial, percentage }) => {
    const { fields } = this.props;
    const oldData = fields.get(index);
    const costPerKgRelative = this.calcRelativeCost(
      oldData.rawMaterial.costPerKg,
      percentage
    );
    fields.splice(index, 1, {
      ...oldData,
      percentage: percentage,
      costPerKgRelative: costPerKgRelative
    });
  };

  saveFormRef = formRef => {
    this.formRef = formRef;
  };

  handleCancel = e => {
    console.log(e);
    this.setState({
      visible: false
    });
  };

  retrieveColumns() {
    const { fields } = this.props;
    return [
      {
        title: "Materia Prima",
        key: "rawMaterial.name",
        dataIndex: "rawMaterial.name"
      },
      {
        title: "Percentuale",
        key: "percentage",
        dataIndex: "percentage",
        render: value => value + " %",
        editable: true,
        onCell: record => ({
          record,
          editable: true,
          dataIndex: "percentage",
          title: "Percentuale",
          handleSave: this.handleSave
        })
      },
      {
        title: "Costo relativo ( al kg )",
        key: "rawMaterial.costPerKgRelative",
        dataIndex: "costPerKgRelative",
        render: CurrencyFormatter
      },
      {
        title: "Azioni",
        key: "action",
        render: (text, record) => (
          <div>
            <Popconfirm
              title="Sei sicuro?"
              onConfirm={() => fields.remove(record.index)}
            >
              <Button icon="delete">Elimina</Button>
            </Popconfirm>
          </div>
        )
      }
    ];
  }

  render() {
    var {
      fields,
      meta,
      children,
      hasFeedback,
      label,
      help,
      ...rest
    } = this.props;
    const hasError = meta.error && meta.invalid;
    const components = {
      body: {
        row: EditableFormRow,
        cell: EditableCell
      }
    };
    if (hasError) {
      help = hasError && meta.error;
    }
    return (
      <Form.Item
        colon={false}
        validateStatus={hasError ? "error" : "success"}
        hasFeedback={hasFeedback && hasError}
        help={help}
      >
        <div className="associationsTableMenu">
          <Button onClick={this.showModal}>Aggiungi Materia prima</Button>
        </div>
        <RawMaterialInSemifinishedForm
          wrappedComponentRef={this.saveFormRef}
          visible={this.state.visible}
          onCancel={this.handleCancel}
          onCreate={this.handleCreate}
        />
        <Table
          components={components}
          rowClassName={() => "editable-row"}
          columns={this.retrieveColumns()}
          {...rest}
          // onChange={input.onChange}
          // onDragStart={input.onDragStart}
          // onDrop={input.onDrop}
          // onFocus={input.onFocus}
          dataSource={this.retrieveDataSource()}
          // value={input.value}
          // children={children}
        />
      </Form.Item>
    );
  }
}

export default RawMaterialAssociation;
