import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { store, history } from "./_helpers";
import { Route, Switch } from "react-router-dom";
import { ConnectedRouter } from "connected-react-router";
import "./style/general.less";
import "font-awesome/css/font-awesome.css";
import * as serviceWorker from "./serviceWorker";
import { LoginPage } from "./_pages";
import App from "./App";
import PrivateRoute from "./_routers/PrivateRoute";

ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <Switch>
        <Route path="/login" exact={true} component={LoginPage} />
        <PrivateRoute path="/" component={App} />
        <Route render={() => <h1>Not Found</h1>} />
      </Switch>
    </ConnectedRouter>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
