import { combineReducers } from "redux";
import list from "./list";
import create from "./create";
import update from "./update";
import del from "./delete";
import show from "./show";
import show_stock_movements from "./showStockMovements";

export default combineReducers({
  list,
  create,
  update,
  del,
  show,
  show_stock_movements
});
