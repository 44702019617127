import React, { Component } from "react";
import { Button } from "antd";
// import "./style.less";

export default class ListMenu extends Component {
  render() {
    const { onClick } = this.props;
    const buttonProps = onClick ? { onClick: onClick } : { href: "create" };
    return (
      <div className="moduleView__menu moduleView__menu--primary">
        <Button {...buttonProps} type="primary" icon="plus">
          Crea nuovo
        </Button>
      </div>
    );
  }
}
