import "antd/lib/icon/style";
import _Icon from "antd/lib/icon";
import "antd/lib/menu/style";
import _Menu from "antd/lib/menu";

function _objectWithoutProperties(obj, keys) {
  var target = {};

  for (var i in obj) {
    if (keys.indexOf(i) >= 0) continue;
    if (!Object.prototype.hasOwnProperty.call(obj, i)) continue;
    target[i] = obj[i];
  }

  return target;
}

import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
var SubMenuAnt = _Menu.SubMenu;

var SubMenu = function SubMenu(_ref) {
  var key = _ref.key,
      onTitleClick = _ref.onTitleClick,
      children = _ref.children,
      icon = _ref.icon,
      label = _ref.label,
      rest = _objectWithoutProperties(_ref, ["key", "onTitleClick", "children", "icon", "label"]);

  return React.createElement(SubMenuAnt, Object.assign({}, rest, {
    key: key,
    onTitleClick: onTitleClick,
    title: React.createElement("span", null, React.createElement(_Icon, {
      type: icon
    }), React.createElement("span", null, label))
  }), children);
};

export default SubMenu;