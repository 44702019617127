import React, { Component } from "react";
import { Field, reduxForm, FieldArray } from "redux-form";
import PropTypes from "prop-types";
import { Form, Button, Card, Tabs, Icon } from "antd";
import { renderField } from "../../_components/fields";
import BudgetVariantAssociation from "../../_components/associations/BudgetVariantAssociation";
import SemifinishedsInBudgetAssociation from "../../_components/associations/SemifinishedsInBudgetAssociation";
import AccessoriesInBudgetAssociation from "../../_components/associations/AccessoriesInBudgetAssociation";
import RawMaterialsInBudgetAssociation from "../../_components/associations/RawMaterialisinBudgetAssociation";

class FormTek extends Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    error: PropTypes.string
  };

  render() {
    const {
      handleSubmit,
      pristine,
      reset,
      submitting,
      handleDelete,
      extraButtons
    } = this.props;
    return (
      <Form layout="vertical" onSubmit={handleSubmit}>
        <Card title="Informazioni">
          <Field
            component={renderField}
            name="year"
            label="Anno"
            type="number"
            placeholder=""
            normalize={v => parseFloat(v)}
          />
        </Card>
        <Card title="Prodotti">
          <FieldArray
            rerenderOnEveryChange={true}
            component={BudgetVariantAssociation}
            name="variantsQuantities"
          />
        </Card>
        {typeof this.props.initialValues !== "undefined" ? (
          <Card title="Budget Calcolato">
            <Tabs defaultActiveKey="3">
              <Tabs.TabPane
                tab={
                  <span>
                    <Icon type="block" />
                    Semilavorati
                  </span>
                }
                key="1"
              >
                <FieldArray
                  rerenderOnEveryChange={true}
                  component={SemifinishedsInBudgetAssociation}
                  name="semifinishedsQuantities"
                />
              </Tabs.TabPane>
              <Tabs.TabPane
                tab={
                  <span>
                    <Icon type="block" />
                    Accessori
                  </span>
                }
                key="2"
              >
                <FieldArray
                  rerenderOnEveryChange={true}
                  component={AccessoriesInBudgetAssociation}
                  name="accessoriesQuantities"
                />
              </Tabs.TabPane>
              <Tabs.TabPane
                tab={
                  <span>
                    <Icon type="block" />
                    Materie prime
                  </span>
                }
                key="3"
              >
                <FieldArray
                  rerenderOnEveryChange={true}
                  component={RawMaterialsInBudgetAssociation}
                  name="rawMaterialsQuantities"
                />
              </Tabs.TabPane>
            </Tabs>
          </Card>
        ) : (
          ""
        )}

        <div className="form__buttons_container">
          <div className="form__buttons_container__content">
            {extraButtons}
            <Button disabled={pristine || submitting} onClick={reset}>
              Cancella valori
            </Button>
            <Button
              type="primary"
              disabled={pristine || submitting}
              htmlType="submit"
            >
              Salva
            </Button>
            {handleDelete && (
              <Button type="danger" icon="delete" onClick={handleDelete}>
                Elimina
              </Button>
            )}
          </div>
        </div>
      </Form>
    );
  }
}

export default reduxForm({
  form: "budget",
  enableReinitialize: true,
  keepDirtyOnReinitialize: true
})(FormTek);
