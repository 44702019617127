import { connect } from "react-redux";
import React, { Component } from "react";
import { Select } from "antd";
import { list, reset } from "../../actions/product/list";

class ProductVariantsSelect extends Component {
  componentDidMount() {
    this.props.list();
  }
  componentWillUnmount() {
    this.props.reset(this.props.eventSource);
  }

  render() {
    var { retrieved, value, mode, ...rest } = this.props;
    const options = retrieved
      ? retrieved["hydra:member"].map(values => {
          var selectValue = JSON.stringify(
            values["variants"].map(variant => {
              return {
                ...variant,
                product: {
                  "@id": values["@id"],
                  name: values.name
                }
              };
            })
          );
          return (
            <Select.Option key={values["@id"]} value={selectValue}>
              {values["name"]}
            </Select.Option>
          );
        })
      : null;
    if (options)
      return (
        <Select
          {...rest}
          placeholder="Seleziona il prodotto che vuoi aggiungere"
          showSearch
          filterOption={(input, option) =>
            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >=
            0
          }
          mode={mode}
          value={value}
        >
          {options}
        </Select>
      );

    return "";
  }
}

const mapStateToProps = state => {
  const { retrieved, loading, error, eventSource } = state.product.list;
  return { retrieved, loading, error, eventSource };
};

const mapDispatchToProps = dispatch => ({
  list: page => dispatch(list(page)),
  reset: eventSource => dispatch(reset(eventSource))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductVariantsSelect);
