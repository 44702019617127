import React from "react";
import { connect } from "react-redux";
import { Route, Redirect } from "react-router-dom";

export const PrivateRoute = ({
  isAuthenticated,
  component: Component,
  location,
  ...rest
}) => (
  <Route
    {...rest}
    component={props =>
      isAuthenticated ? (
        <Component {...props} />
      ) : (
        <Redirect to={{ pathname: "/login", state: { referrer: location } }} />
      )
    }
  />
);
const mapStateToProps = ({ authentication, router }) => ({
  isAuthenticated: !!authentication.loggedIn,
  location: router.location
});

export default connect(mapStateToProps)(PrivateRoute);
