import { connect } from "react-redux";
import React, { Component } from "react";
import { Select } from "antd";
import { list, reset } from "../../actions/accessory/list";

class AccessorySelect extends Component {
  componentDidMount() {
    this.props.list();
  }
  componentWillUnmount() {
    this.props.reset(this.props.eventSource);
  }

  render() {
    var { retrieved, value, mode, ...rest } = this.props;
    const options = retrieved
      ? retrieved["hydra:member"].map(values => (
          <Select.Option key={values["@id"]} value={values["@id"]}>
            {values["name"]}
          </Select.Option>
        ))
      : null;
    if (options)
      return (
        <Select
          {...rest}
          placeholder={
            mode === "multiple"
              ? "Seleziona gli accessori che vuoi associare"
              : "Seleziona un accessorio"
          }
          showSearch
          filterOption={(input, option) =>
            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >=
            0
          }
          mode={mode}
          value={value ? value : mode === "multiple" ? [] : null}
        >
          {options}
        </Select>
      );

    return "";
  }
}

const mapStateToProps = state => {
  const { retrieved, loading, error, eventSource } = state.accessory.list;
  return { retrieved, loading, error, eventSource };
};

const mapDispatchToProps = dispatch => ({
  list: page => dispatch(list(page)),
  reset: eventSource => dispatch(reset(eventSource))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AccessorySelect);
