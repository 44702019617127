import { layoutConstants } from "../_constants";

export const layoutActions = {
  setOpenKeys,
  toggleSiderCollapse
};

function setOpenKeys(openKeys) {
  return { type: layoutConstants.SETOPENKEYS, openKeys };
}

function toggleSiderCollapse(){
  return { type: layoutConstants.TOGGLESIDERCOLLAPSE};
}