import React from "react";
import { Layout, Menu, Icon } from "antd";
import { connect } from "react-redux";
import { userActions } from "../../_actions";

import "./style.less";
const SubMenu = Menu.SubMenu;
const HeaderW = Layout.Header;

export const Header = ({ user, startLogout }) => {
  const handleClick = ({ item, key }) => {
    switch (key) {
      case "logout":
        startLogout();
        break;
      default:
    }
  };
  return (
    <HeaderW
      style={{ background: "#fff", padding: 0 }}
      className="webtekHeader"
    >
      <Menu
        className="webtekHeader__menu"
        mode="horizontal"
        onClick={handleClick}
      >
        <SubMenu
          style={{
            float: "right"
          }}
          title={
            <span>
              <Icon type="user" />
              {user.username}
            </span>
          }
        >
          <Menu.Item key="logout">Logout</Menu.Item>
        </SubMenu>
      </Menu>
    </HeaderW>
  );
};

const mapDispatchToProps = dispatch => ({
  startLogout: () => dispatch(userActions.logout())
});

const mapStateToProps = ({ authentication }) => ({
  user: authentication.user
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Header);
