import React, { Component } from "react";
import { Form, InputNumber, Modal } from "antd";
import RawMaterialSelect from "../select/RawMaterialSelect";

class RawMaterialInSemifinishedForm extends Component {
  render() {
    const { visible, onCancel, onCreate, form } = this.props;
    const { getFieldDecorator } = form;
    return (
      <Modal
        title="Aggiunta Materia Prima"
        okText="Aggiungi"
        visible={visible}
        onOk={onCreate}
        onCancel={onCancel}
      >
        <Form layout="vertical">
          <Form.Item label="Materia Prima">
            {getFieldDecorator("rawMaterial", {
              rules: [
                {
                  required: true,
                  message: "Devi selezionare una materia prima"
                }
              ]
            })(<RawMaterialSelect getCost />)}
          </Form.Item>
          <Form.Item label="Percentuale">
            {getFieldDecorator("percentage", {
              initialValue: 1,
              rules: [
                {
                  required: true,
                  message: "Devi inserire un valore compreso fra 1 e 100",
                  min: 1,
                  max: 100,
                  type: "number"
                }
              ]
            })(
              <InputNumber
                min={1}
                max={100}
                formatter={value => `${value}%`}
                parser={value => value.replace("%", "")}
              />
            )}
          </Form.Item>
        </Form>
      </Modal>
    );
  }
}

export default Form.create({ name: "raw_material_in_semifinished_form" })(
  RawMaterialInSemifinishedForm
);
