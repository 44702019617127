import React, { Component } from "react";
import { Field, reduxForm } from "redux-form";
import PropTypes from "prop-types";
import { Form, Card, Row, Col } from "antd";
import { translate } from "../../_translations/accessory";
import { renderField } from "../../_components/fields";
import {
  ColorSelectField,
  UnitOfMeasureSelectField
} from "../../_components/fields";
import {
  CurrencyFormatter,
  QuantityFormatter,
  DecimalNormalizer
} from "../../_services/field_formatters";
import { FormButtonsContainer } from "../../_layouts";

class FormTek extends Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    error: PropTypes.string
  };

  render() {
    const {
      handleSubmit,
      pristine,
      reset,
      submitting,
      handleDelete,
      extraButtons
    } = this.props;

    return (
      <Form layout="vertical" onSubmit={handleSubmit}>
        <Card title="Informazioni">
          <Row>
            <Col>
              <Field
                component={renderField}
                name="name"
                label={translate("name")}
                type="text"
                placeholder="Accessorio"
                required={true}
              />
            </Col>
          </Row>
          <Row gutter={16}>
            <Col sm={12}>
              <Field
                component={ColorSelectField}
                name="color"
                label={translate("color")}
                type="text"
                placeholder=""
              />
            </Col>
            <Col sm={12}>
              <Field
                component={UnitOfMeasureSelectField}
                name="unitOfMeasure"
                label={translate("unitOfMeasure")}
                type="text"
                placeholder=""
              />
            </Col>
          </Row>
          <Row gutter={16}>
            <Col sm={12}>
              <Field
                component={renderField}
                name="quantity"
                format={QuantityFormatter}
                normalize={DecimalNormalizer}
                label={translate("quantity")}
                type="text"
                placeholder="1,20"
                required={true}
              />
            </Col>
            <Col sm={12}>
              <Field
                component={renderField}
                name="costPerUnit"
                format={CurrencyFormatter}
                normalize={DecimalNormalizer}
                label={translate("costPerUnit")}
                type="text"
                placeholder="€ 10,4560"
                required={true}
              />
            </Col>
          </Row>
        </Card>
        <FormButtonsContainer
          extraButtons={extraButtons}
          disabled={pristine || submitting}
          handleDelete={handleDelete}
          reset={reset}
        />
      </Form>
    );
  }
}

export default reduxForm({
  form: "accessory",
  enableReinitialize: true,
  keepDirtyOnReinitialize: true
})(FormTek);
