const normalizeNumber = input => {
  var addComma = false;
  var lastCharIsComma = false;
  var previousInput = input.slice(0, -1);
  const insertedChar = input[input.length - 1];
  var numberOfDecimals = 0;
  var values = input.split(".");
  if (values.length > 1) {
    numberOfDecimals = values[1].length;
  }

  if (previousInput[previousInput.length - 1] === ".") {
    previousInput = previousInput.slice(0, -1);
    lastCharIsComma = true;
  }
  if (insertedChar === ".") {
    addComma = true;
    input = previousInput;
  }
  if (insertedChar !== "." && isNaN(parseInt(insertedChar, 10))) {
    input = previousInput;
    if (lastCharIsComma) {
      addComma = true;
    }
  }
  if ((input.match(/\./g) || []).length > 1) {
    input =
      input.slice(0, input.lastIndexOf(".")) +
      input.slice(input.lastIndexOf(".") + 1, input.length);
  }
  const needToAddComma = addComma && !previousInput.includes(".");
  return {
    input: input,
    needToAddComma: needToAddComma,
    numberOfDecimals: numberOfDecimals
  };
};

export const CurrencyFormatter = input => {
  if (!input) return;
  var common = normalizeNumber(input);
  input = common.input;
  var convertedInput =
    "€ " +
    new Intl.NumberFormat("it-IT", {
      minimumFractionDigits:
        common.numberOfDecimals > 4 ? 4 : common.numberOfDecimals,
      maximumFractionDigits: 4
    }).format(input);
  if (common.needToAddComma) convertedInput += ",";

  return convertedInput;
};

export const QuantityFormatter = input => {
  if (!input) return;
  var common = normalizeNumber(input);
  input = common.input;
  var convertedInput = new Intl.NumberFormat("it-IT", {
    minimumFractionDigits:
      common.numberOfDecimals > 2 ? 2 : common.numberOfDecimals,
    maximumFractionDigits: 2
  }).format(input);
  if (common.needToAddComma) convertedInput += ",";

  return convertedInput;
};

export const DecimalNormalizer = value => {
  var valueNormalized = value.replace(/[^0-9,]/g, "").replace(/,/g, ".");
  return valueNormalized;
};
