import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import {
  retrieve,
  reset
} from "../../actions/productvariant/showStockMovements";
import requireAuthentication from "../../_hoc/requireAuthentication";
import { ModuleView } from "../../_layouts";
import { TableClientSide } from "webtek-components";
import moment from "moment";
import "moment/locale/it";

class ShowStockMovements extends Component {
  static propTypes = {
    retrieved: PropTypes.object,
    loading: PropTypes.bool.isRequired,
    error: PropTypes.string,
    eventSource: PropTypes.instanceOf(EventSource),
    retrieve: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired
  };

  componentDidMount() {
    this.props.retrieve(decodeURIComponent(this.props.match.params.id));
  }

  componentWillUnmount() {
    this.props.reset(this.props.eventSource);
  }
  retrieveColumns() {
    return [
      {
        title: "Data",
        key: "date",
        dataIndex: "date",
        render: value =>
          moment(value)
            .locale("it")
            .format("L LT")
      },
      {
        title: "Tipo",
        key: "type",
        dataIndex: "type",
        filter: {
          type: "custom"
        },
        filters: [
          {
            text: "Manuale",
            value: "manual"
          },
          {
            text: "Commessa",
            value: "commission"
          },
          {
            text: "Commessa - Qtà impegnata",
            value: "commission_busy"
          }
        ],
        onFilter: (value, record) => record.type.indexOf(value) === 0,
        render: type => {
          switch (type) {
            case "manual":
              return "Manuale";
            case "commission":
              return "Commessa";
            case "commission_busy":
              return "Commessa - Qtà impegnata";
            default:
              return "";
          }
        }
      },
      {
        title: "Quantità",
        key: "movement",
        dataIndex: "movement",
        sorter: {
          type: "numeric"
        },
        render: value => {
          if (value > 0)
            return <span style={{ color: "green" }}>+{value}</span>;
          return <span style={{ color: "red" }}>{value}</span>;
        }
      },
      {
        title: "Commessa associata",
        key: "commission",
        dataIndex: "commission",
        filter: null,
        render: value =>
          value ? (
            <Link to={`/commissions/edit/${encodeURIComponent(value)}`}>
              {value}
            </Link>
          ) : null
      }
    ];
  }
  retrieveDataSource() {
    if (this.props.retrieved && this.props.retrieved["hydra:member"]) {
      return this.props.retrieved["hydra:member"].map(item => {
        return {
          key: item["@id"],
          ...item
        };
      });
    }
    return [];
  }

  render() {
    const { loading, error } = this.props;
    return (
      <ModuleView
        type="list"
        onBack={() => window.history.back()}
        title={
          "Elenco movimenti magazzino variante " +
          (this.props.retrieved && this.props.retrieved["@id"])
        }
        loading={loading}
        error={error}
      >
        <TableClientSide
          dataSource={this.retrieveDataSource()}
          columns={this.retrieveColumns()}
          noAction
        />
      </ModuleView>
    );
  }
}

const mapStateToProps = state => ({
  retrieved: state.productvariant.show_stock_movements.retrieved,
  error: state.productvariant.show_stock_movements.error,
  loading: state.productvariant.show_stock_movements.loading,
  eventSource: state.productvariant.show_stock_movements.eventSource
});

const mapDispatchToProps = dispatch => ({
  retrieve: id => dispatch(retrieve(id)),
  reset: eventSource => dispatch(reset(eventSource))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(requireAuthentication(ShowStockMovements));
