const translations = {
  name: "Nome",
  color: "Colore",
  unitOfMeasure: "Unità di misura",
  quantity: "Quantità",
  costPerUnit: "Costo per unità",
  products: "Prodotti"
};

export const translate = key => {
  if (key in translations) {
    return translations[key];
  }
  return key;
};
