import React, { Component } from "react";
import { connect } from "react-redux";
import { Field, reduxForm, FieldArray, formValueSelector } from "redux-form";
import PropTypes from "prop-types";
import { Form, Button, Card, Icon, Tabs, Popconfirm, Progress } from "antd";
import { renderField, CustomerSelectField } from "../../_components/fields";
import CommissionVariantAssociation from "../../_components/associations/CommissionVariantAssociation";
import AccessoriesInCommissionAssociation from "../../_components/associations/AccessoriesInCommissionAssociation";
import RawMaterialsInCommissionAssociation from "../../_components/associations/RawMaterialsInCommissionAssociation";
import { statusTrans } from "./common_functions";

class FormTek extends Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    error: PropTypes.string
  };

  //TODO sta cosa non va bene, bisogna capire come diamine gestire il cambio di stato.. a volte si rompe perchè c'è l'errore ma al then non arriva nulla
  changeStatus = status => {
    const oldStatus = this.props.status;
    this.props.change("status", status);
    const submitter = this.props.handleSubmit(values =>
      this.props.onSubmit({ ...values, status: status })
    );
    submitter().then(action => {
      if (
        typeof action !== "undefined" &&
        typeof action._error !== "undefined"
      ) {
        this.props.change("status", oldStatus);
      }
    });
  };

  formatStatusProgressStatus = status => {
    switch (status) {
      case "Completa":
        return "success";
      case "Eliminata":
        return "exception";
      case "Da lavorare":
        return "normal";
      default:
        return "active";
    }
  };

  formatStatusProgress = status => {
    switch (status) {
      case "Completa":
      case "Eliminata":
        return 100;
      case "Sospesa":
      case "In lavorazione":
        return 50;
      default:
        return 0;
    }
  };

  formatStatusProgressText = status => {
    return <div style={{ fontSize: "12px" }}>{status}</div>;
  };

  render() {
    const {
      handleSubmit,
      pristine,
      reset,
      submitting,
      extraButtons,
      warnings
    } = this.props;
    var stateChangeButtons = null;
    var status = this.props.status;
    if (status === "not_created") {
      stateChangeButtons = (
        <Button type="primary" onClick={() => this.changeStatus("created")}>
          Crea la commessa
        </Button>
      );
    }
    if (status === "created") {
      stateChangeButtons = (
        <span>
          {/* <Button
            type="primary"
            disabled={pristine || submitting}
            htmlType="submit"
          >
            Modifica la commessa ( TO DO )
          </Button> */}
          <Button type="danger" onClick={() => this.changeStatus("deleted")}>
            Elimina commessa
          </Button>
          {warnings ? (
            <Popconfirm
              title="Ci sono alcuni avvertimenti, vuoi procedere comunque?"
              onConfirm={() => this.changeStatus("wip")}
              okText="Si"
            >
              <Button type="primary">Metti in lavorazione</Button>
            </Popconfirm>
          ) : (
            <Button type="primary" onClick={() => this.changeStatus("wip")}>
              Metti in lavorazione
            </Button>
          )}
        </span>
      );
    }
    if (status === "wip") {
      stateChangeButtons = (
        <span>
          <Button type="danger" onClick={() => this.changeStatus("deleted")}>
            Elimina commessa
          </Button>
          <Button type="primary" onClick={() => this.changeStatus("on_hold")}>
            Sospendi commessa
          </Button>
          <Button type="primary" onClick={() => this.changeStatus("done")}>
            Concludi commessa
          </Button>
        </span>
      );
    }
    if (status === "on_hold") {
      stateChangeButtons = (
        <span>
          <Button type="danger" onClick={() => this.changeStatus("deleted")}>
            Elimina commessa
          </Button>
          <Button type="primary" onClick={() => this.changeStatus("wip")}>
            Rimetti in lavorazione la commessa
          </Button>
        </span>
      );
    }

    return (
      <Form layout="vertical" onSubmit={handleSubmit}>
        <Card title="Informazioni">
          <Field
            component={CustomerSelectField}
            name="customer"
            label="Cliente"
            type="text"
            placeholder=""
          />
          <Field
            component={({ input }) => {
              return (
                <Progress
                  style={{
                    display: "block",
                    margin: "auto",
                    textAlign: "center"
                  }}
                  type="dashboard"
                  percent={this.formatStatusProgress(input.value)}
                  status={this.formatStatusProgressStatus(input.value)}
                  width={100}
                  format={() => this.formatStatusProgressText(input.value)}
                />
              );
            }}
            readOnly
            name="status"
            label="Stato"
            type="text"
            format={value => statusTrans(value)}
            placeholder=""
          />
          {/* <Field
            component={renderField}
            name="product"
            label={translate("product")}
            type="text"
            placeholder=""
            required={true}
          /> */}
          <FieldArray
            rerenderOnEveryChange={true}
            component={CommissionVariantAssociation}
            isProductSelectable={status === "not_created"}
            isEstimatedQuantityEditable={status === "not_created"}
            isActualQuantityVisible={
              status !== "created" && status !== "not_created"
            }
            isActualQuantityEditable={status === "wip"}
            name="variants"
          />

          <Field
            component={renderField}
            name="notes"
            label="Note"
            type="longtext"
            placeholder=""
          />
        </Card>
        {typeof this.props.initialValues.accessories !== "undefined" ? (
          <Card title="Materiali richiesti">
            <Tabs defaultActiveKey="2">
              <Tabs.TabPane
                tab={
                  <span>
                    <Icon type="block" />
                    Accessori
                  </span>
                }
                key="1"
              >
                <FieldArray
                  rerenderOnEveryChange={true}
                  component={AccessoriesInCommissionAssociation}
                  isActualQuantityVisible={
                    status !== "created" && status !== "not_created"
                  }
                  isActualQuantityEditable={status === "wip"}
                  name="accessories"
                />
              </Tabs.TabPane>
              <Tabs.TabPane
                tab={
                  <span>
                    <Icon type="block" />
                    Materie prime
                  </span>
                }
                key="2"
              >
                <FieldArray
                  rerenderOnEveryChange={true}
                  component={RawMaterialsInCommissionAssociation}
                  isActualQuantityVisible={
                    status !== "created" && status !== "not_created"
                  }
                  isActualQuantityEditable={status === "wip"}
                  name="rawMaterials"
                />
              </Tabs.TabPane>
            </Tabs>
          </Card>
        ) : null}
        <div className="form__buttons_container">
          <div className="form__buttons_container__content">
            {extraButtons}
            <Button disabled={pristine || submitting} onClick={reset}>
              Cancella valori
            </Button>
            {stateChangeButtons}
          </div>
        </div>
      </Form>
    );
  }
}

const selector = formValueSelector("commission");
FormTek = connect(state => {
  const status = selector(state, "status");
  var warnings = selector(state, "warnings");
  if (warnings && !Object.keys(warnings).length) {
    warnings = null;
  }
  return {
    status: status,
    warnings: warnings
  };
})(FormTek);

const onFieldsChange = ({ variants, status }, dispatch, props, oldValues) => {
  console.log("onFieldsChange");
  if (typeof variants !== "undefined" && variants.length) {
    const product = variants[0].variant.product["@id"];
    var oldProduct = null;
    if (typeof oldValues.variants !== "undefined" && oldValues.variants.length)
      oldProduct = oldValues.variants[0].variant.product["@id"];
    if (product !== oldProduct) {
      dispatch(props.change("product", product));
    }
  }
  // if (status !== oldValues.status) {
  //   console.log(props);
  //   props.submit();
  // }
};

export default reduxForm({
  form: "commission",
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  onChange: onFieldsChange,
  initialValues: {
    product: "",
    status: "not_created"
  }
})(FormTek);
