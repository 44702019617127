import React, { Component } from "react";
import { Table, Form, InputNumber, Tooltip, Progress } from "antd";

const EditableContext = React.createContext();

const EditableRow = ({ form, index, ...props }) => (
  <EditableContext.Provider value={form}>
    <tr {...props} />
  </EditableContext.Provider>
);

const EditableFormRow = Form.create()(EditableRow);

class EditableCell extends React.Component {
  state = {
    editing: false
  };

  toggleEdit = () => {
    const editing = !this.state.editing;
    this.setState({ editing }, () => {
      if (editing) {
        this.input.focus();
      }
    });
  };

  save = e => {
    const { record, handleSave } = this.props;
    this.form.validateFields((error, values) => {
      if (error && error[e.currentTarget.id]) {
        return;
      }
      this.toggleEdit();
      handleSave({ ...record, ...values });
    });
  };

  render() {
    const { editing } = this.state;
    const {
      editable,
      dataIndex,
      title,
      record,
      index,
      handleSave,
      ...restProps
    } = this.props;
    return (
      <td {...restProps}>
        {editable ? (
          <EditableContext.Consumer>
            {form => {
              this.form = form;
              return editing ? (
                <Form.Item style={{ margin: 0 }}>
                  {form.getFieldDecorator(dataIndex, {
                    rules: [
                      {
                        required: true,
                        message: "Devi inserire un valore numerico",
                        min: 0,
                        type: "number"
                      }
                    ],
                    initialValue: record[dataIndex]
                  })(
                    <InputNumber
                      ref={node => (this.input = node)}
                      onPressEnter={this.save}
                      onBlur={this.save}
                      min={0}
                      // formatter={value => `${value}%`}
                      // parser={value => value.replace("%", "")}
                    />
                  )}
                </Form.Item>
              ) : (
                <div
                  className="editable-cell-value-wrap"
                  style={{ paddingRight: 24 }}
                  onClick={this.toggleEdit}
                >
                  {restProps.children}
                </div>
              );
            }}
          </EditableContext.Consumer>
        ) : (
          restProps.children
        )}
      </td>
    );
  }
}

class AccessoriesInBudgetAssociation extends Component {
  retrieveColumns() {
    return [
      {
        title: "Accessorio",
        key: "accessory.name",
        dataIndex: "accessory.name"
      },
      {
        title: "Quantità",
        key: "quantity",
        dataIndex: "quantity",
        render: (value, record) => {
          return (
            value +
            " " +
            (record.accessory.unitOfMeasure
              ? record.accessory.unitOfMeasure.symbol
              : "")
          );
        }
        // editable: true,
        // onCell: record => ({
        //   record,
        //   editable: true,
        //   dataIndex: "quantity",
        //   title: "Quantità",
        //   handleSave: this.handleSave
        // })
      },
      {
        title: "Quantità utilizzata",
        key: "usedQuantity",
        dataIndex: "usedQuantity",
        render: record => record + " g"
      },
      {
        title: "Stato",
        key: "percentage",
        dataIndex: "percentage",
        render: (value, record) => {
          return (
            <Tooltip
              title={
                record.usedQuantity +
                "/" +
                record.quantity +
                ", rimanente: " +
                record.remainingQuantity
              }
              style={{ width: 100 }}
            >
              <Progress
                percent={value}
                size="small"
                status={value >= 100 ? "exception" : "normal"}
              />
            </Tooltip>
          );
        }
      }
    ];
  }

  retrieveDataSource = () => {
    const { fields } = this.props;
    return fields.length
      ? fields.map((name, index, values) => {
          var current = values.get(index);
          return {
            key: name,
            index: index,
            accessory: current.accessory,
            quantity: current.quantity,
            usedQuantity: current.usedQuantity,
            remainingQuantity: current.remainingQuantity,
            percentage: current.percentage
          };
        })
      : [];
  };

  // handleSave = ({ index, key, quantity }) => {
  //   console.log("handleSave");
  //   const { fields } = this.props;
  //   const oldData = fields.get(index);
  //   fields.splice(index, 1, {
  //     ...oldData,
  //     quantity: quantity
  //   });
  // };

  render() {
    const { fields, meta, children, hasFeedback, label, ...rest } = this.props;
    const hasError = meta.error && meta.invalid;
    const components = {
      body: {
        row: EditableFormRow,
        cell: EditableCell
      }
    };
    return (
      <Form.Item
        colon={false}
        validateStatus={hasError ? "error" : "success"}
        hasFeedback={hasFeedback && hasError}
        help={hasError && meta.error}
      >
        <Table
          components={components}
          rowClassName={() => "editable-row"}
          columns={this.retrieveColumns()}
          {...rest}
          // onChange={input.onChange}
          // onDragStart={input.onDragStart}
          // onDrop={input.onDrop}
          // onFocus={input.onFocus}
          dataSource={this.retrieveDataSource()}
          // value={input.value}
          // children={children}
        />
      </Form.Item>
    );
  }
}

export default AccessoriesInBudgetAssociation;
