import React, { Component } from "react";
import { Form, Modal } from "antd";
import ProductSelect from "../select/ProductVariantsSelect";

class ProductVariantsInBudgetForm extends Component {
  render() {
    const { visible, onCancel, onCreate, form } = this.props;
    const { getFieldDecorator } = form;
    return (
      <Modal
        title="Aggiunta prodotti"
        okText="Aggiungi"
        visible={visible}
        onOk={onCreate}
        onCancel={onCancel}
      >
        <Form layout="vertical">
          <Form.Item label="Prodotto">
            {getFieldDecorator("variants", {
              rules: [
                {
                  required: true,
                  message: "Devi selezionare un prodotto"
                }
              ]
            })(<ProductSelect />)}
          </Form.Item>
        </Form>
      </Modal>
    );
  }
}

export default Form.create({ name: "components_percentage_form" })(
  ProductVariantsInBudgetForm
);
