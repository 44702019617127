import React from "react";
import { connect } from "react-redux";

const requireAuthentication = WrappedComponent => {
  const requireAuthenticationComponent = props => {
    const { isAuthenticated, modules, location } = props;
    let hasPermissions = false;
    if (isAuthenticated) {
      let routes = location.pathname.split("/");
      let modulo = modules.filter(function(module) {
        return module.name === routes[1];
      })[0];
      if (modulo) {
        if (typeof routes[2] !== "undefined" && routes[2].trim() !== "") {
          if (routes[2] in modulo.permissions) {
            hasPermissions = !!modulo.permissions[routes[2]];
          }
        } else {
          hasPermissions = true;
        }
      }
    }
    if (hasPermissions) return <WrappedComponent {...props} />;
    // history.push("/unauthorized");
    return <p>Non sei autorizzato a entrare in questa sezione</p>;
  };
  const mapStateToProps = ({ authentication, router }) => ({
    isAuthenticated: !!authentication.loggedIn,
    modules: authentication.user.modules,
    location: router.location
  });
  return connect(mapStateToProps)(requireAuthenticationComponent);
};

export default requireAuthentication;
