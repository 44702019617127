import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { list, reset } from "../../actions/rawmaterial/list";
import { TableClientSide } from "webtek-components";
import { ListMenu, ModuleView } from "../../_layouts";
import { translate } from "../../_translations/rawmaterial";
import {
  CurrencyFormatter,
  QuantityFormatter
} from "../../_services/field_formatters";
import { del } from "../../actions/rawmaterial/delete";
import { update } from "../../actions/rawmaterial/update";
import { QuantityInput, CurrencyInput } from "../../input";
import {
  EditableFormRow,
  EditableCell
} from "../../_components/table/EditableTable";
import { Progress, Tooltip, Divider, Icon, Popconfirm } from "antd";

class List extends Component {
  static propTypes = {
    retrieved: PropTypes.object,
    loading: PropTypes.bool.isRequired,
    error: PropTypes.string,
    eventSource: PropTypes.instanceOf(EventSource),
    deletedItem: PropTypes.object,
    list: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired
  };

  componentDidMount() {
    this.props.list(
      this.props.match.params.page &&
        decodeURIComponent(this.props.match.params.page)
    );
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.match.params.page !== nextProps.match.params.page)
      nextProps.list(
        nextProps.match.params.page &&
          decodeURIComponent(nextProps.match.params.page)
      );
  }

  componentWillUnmount() {
    this.props.reset(this.props.eventSource);
  }

  render() {
    const { loading, error } = this.props;
    const components = {
      body: {
        row: EditableFormRow,
        cell: EditableCell
      }
    };
    return (
      <ModuleView
        type="list"
        title="Elenco Materie prime"
        subTitle="Visualizza le materie prime presenti"
        loading={loading}
        notifications={this.retrieveNotifications()}
        error={error}
        errors={this.retrieveErrors()}
      >
        <ListMenu />
        <TableClientSide
          rowClassName={() => "editable-row"}
          components={components}
          dataSource={this.retrieveDataSource()}
          columns={this.retrieveColumns()}
          scroll={{ x: 800 }}
          onRowDelete={this.deleteEntity}
        />
      </ModuleView>
    );
  }

  deleteEntity = entityValues => {
    this.props.del(entityValues).then(this.reload);
  };
  reload = () => {
    this.props.list(
      this.props.match.params.page &&
        decodeURIComponent(this.props.match.params.page)
    );
  };

  getUniqueTypesOfRawMaterialsFromResult() {
    if (this.props.retrieved && this.props.retrieved["hydra:member"]) {
      var types = [];
      var foundTypes = [];
      this.props.retrieved["hydra:member"].forEach(item => {
        if (item["type"]) {
          if (!foundTypes.includes(item["type"].id)) {
            foundTypes.push(item["type"].id);
            types.push({
              text: item["type"].name,
              value: item["type"].name
            });
          }
        }
      });
      return types;
    }
    return null;
  }

  retrieveColumns() {
    return [
      {
        title: translate("name"),
        key: "name",
        dataIndex: "name",
        editable: true,
        onCell: record => ({
          record,
          editable: true,
          dataIndex: "name",
          title: "Nome",
          handleSave: this.handleSave
        })
      },
      {
        title: translate("type"),
        key: "type",
        dataIndex: "type",
        filter: {
          type: "custom"
        },
        filters: this.getUniqueTypesOfRawMaterialsFromResult(),
        // specify the condition of filtering result
        // here is that finding the name started with `value`
        onFilter: (value, record) => record.type.indexOf(value) === 0
      },
      {
        title: "Componenti",
        key: "componentsPercentage",
        dataIndex: "componentsPercentage",
        sorter: null,
        filter: null,
        render: componentsPercentage => {
          var components = componentsPercentage.map(
            ({ component, percentage }, index) => {
              return (
                <span key={component.id}>
                  <Link
                    to={`../components/edit/${encodeURIComponent(
                      component["@id"]
                    )}`}
                  >
                    {component.name}
                  </Link>
                  {" " + percentage + "%"}
                  {index !== componentsPercentage.length - 1 ? ", " : ""}
                </span>
              );
            }
          );
          return components;
        }
      },
      {
        title: "Colore",
        key: "colorName",
        dataIndex: "colorName"
      },
      {
        title: "Costo al kg",
        key: "costPerKg",
        dataIndex: "costPerKg",
        sorter: {
          type: "numeric"
        },
        editable: true,
        onCell: record => ({
          record,
          editable: true,
          dataIndex: "costPerKg",
          title: "Costo al Kg",
          editComponent: CurrencyInput,
          handleSave: this.handleSave
        }),
        render: CurrencyFormatter
      },
      {
        title: translate("quantity"),
        key: "quantity",
        dataIndex: "quantity",
        sorter: {
          type: "numeric"
        },
        editable: true,
        onCell: record => ({
          record,
          editable: true,
          dataIndex: "quantity",
          title: "Quantità",
          editComponent: QuantityInput,
          handleSave: this.handleSave
        }),
        render: value => {
          return QuantityFormatter(value) + " g";
        }
      },
      {
        title: "Utilizzo budget",
        key: "budgetUsage",
        dataIndex: "budgetUsage",
        sorter: (a, b) => {
          return a.budgetUsage.percentage > b.budgetUsage.percentage;
        },
        render: value => {
          return (
            <Tooltip
              title={
                value.used +
                "/" +
                value.total +
                ", rimanente: " +
                value.remaining
              }
              style={{ width: 100 }}
            >
              <Progress
                percent={value.percentage}
                status={value.percentage >= 100 ? "exception" : "normal"}
                size="small"
              />{" "}
            </Tooltip>
          );
        }
      },
      {
        title: "Quantità scartata",
        key: "wastedQuantity",
        dataIndex: "wastedQuantity",
        render: value => value + " g",
        sorter: {
          type: "numeric"
        },
        filter: null
      },
      {
        title: "Azioni",
        key: "action",
        fixed: "right",
        filter: null,
        sorter: null,
        width: 125,
        render: (text, record) => (
          <div className="table__actionsMenu">
            {/* <Link to={`show/${encodeURIComponent(record["@id"])}`}>
              <Icon type="search" />
            </Link> */}
            <Divider type="vertical" />
            <Tooltip title="Modifica la materia prima">
              <Link to={`edit/${encodeURIComponent(record["@id"])}`}>
                <Icon type="edit" />
              </Link>
            </Tooltip>
            <Divider type="vertical" />
            <Tooltip title="Visualizza i movimenti di magazzino">
              <Link to={`stock_movements/${encodeURIComponent(record["@id"])}`}>
                <Icon type="stock" />
              </Link>
            </Tooltip>
            <Divider type="vertical" />
            <Tooltip title="Elimina la materia prima">
              <Popconfirm
                title="Sei sicuro di voler eliminare questo elemento"
                onConfirm={() => {
                  this.deleteEntity(record);
                }}
                okText="Si"
                cancelText="No"
              >
                <Icon type="delete" />
              </Popconfirm>
            </Tooltip>
          </div>
        )
      }
    ];
  }

  retrieveDataSource() {
    if (this.props.retrieved && this.props.retrieved["hydra:member"]) {
      return this.props.retrieved["hydra:member"].map(item => {
        return {
          key: item["@id"],
          "@id": item["@id"],
          id: item["@id"],
          name: item["name"],
          type: item["type"] ? item["type"].name : null,
          componentsPercentage: item["componentsPercentage"],
          color: item["color"],
          colorName: item["color"] ? item["color"].name : null,
          costPerKg: item["costPerKg"],
          quantity: item["quantity"],
          budgetUsage: item["budgetUsage"],
          wastedQuantity: item["wastedQuantity"]
        };
      });
    }
    return [];
  }

  handleSave = values => {
    const { update } = this.props;
    update(values, {
      quantity: values.quantity,
      name: values.name,
      costPerKg: values.costPerKg
    }).then(this.reload);
  };

  retrieveErrors = () => {
    var errors = [];
    if (this.props.updateError) {
      errors.push({
        message: "Errore durante l'aggiornamento",
        description: this.props.updateError
      });
    }
    if (this.props.deleteError) {
      errors.push({
        message: "Errore durante l'eliminazione",
        description: this.props.deleteError
      });
    }
    return errors;
  };

  retrieveNotifications = () => {
    var notifications = [];
    if (this.props.created) {
      notifications.push({
        message: this.props.created["@id"] + " creato."
      });
    }
    if (this.props.updated) {
      notifications.push({
        message: this.props.updated["@id"] + " aggiornato."
      });
    }
    if (this.props.deletedItem) {
      notifications.push({
        message: this.props.deletedItem["@id"] + " eliminato."
      });
    }
    return notifications;
  };

  renderLinks = (type, items) => {
    if (Array.isArray(items)) {
      return items.map((item, i) => (
        <div key={i}>{this.renderLinks(type, item)}</div>
      ));
    }

    return (
      <Link to={`../${type}/show/${encodeURIComponent(items)}`}>{items}</Link>
    );
  };
}

const mapStateToProps = state => {
  const { retrieved, loading, error, eventSource } = state.rawmaterial.list;
  const deletedItem = state.rawmaterial.del.deleted;

  return {
    retrieved,
    loading,
    error,
    eventSource,
    deletedItem,
    deleteError: state.rawmaterial.del.error,
    deleteLoading: state.rawmaterial.del.loading,
    updateError: state.rawmaterial.update.updateError,
    updateLoading: state.rawmaterial.update.updateLoading,
    updated: state.rawmaterial.update.updated
  };
};

const mapDispatchToProps = dispatch => ({
  list: page => dispatch(list(page)),
  update: (item, values) => dispatch(update(item, values)),
  del: item => dispatch(del(item)),
  reset: eventSource => dispatch(reset(eventSource))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(List);
