export const statusTrans = status => {
  const statusTranslationTable = {
    not_created: "In fase di creazione",
    created: "Da lavorare",
    wip: "In lavorazione",
    on_hold: "Sospesa",
    done: "Completa",
    deleted: "Eliminata"
  };
  if (status in statusTranslationTable) {
    return statusTranslationTable[status];
  }
  return "Sconosciuto(" + status + ")";
};
