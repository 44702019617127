import React, { Component } from "react";
import requireAuthentication from "../../_hoc/requireAuthentication";
import { Modal, Alert } from "antd";

class CreateModalType extends Component {
  render() {
    const { item, visible, loading, error, Form, title } = this.props;
    return (
      <Modal
        title={title}
        okText="Crea"
        visible={visible}
        onOk={this.onOk}
        onCancel={this.onCancel}
        confirmLoading={loading}
      >
        {error ? (
          <Alert
            key={"error"}
            message={error}
            description={error}
            type="error"
            showIcon
            closable
          />
        ) : null}
        <Form
          onSubmit={this.handleSubmit}
          values={item}
          ref={this.attachNode}
          renderMenu={false}
        />
      </Modal>
    );
  }

  onOk = () => {
    this.form.submit();
  };

  onCancel = () => {
    this.form.reset();
    this.props.onCancel();
  };

  attachNode = node => {
    this.form = node;
  };

  handleSubmit = values => {
    const { create, onCreate } = this.props;
    return create(values).then(() => {
      this.form.reset();
      onCreate();
    });
  };
}

export default requireAuthentication(CreateModalType);
