import "antd/lib/table/style";
import _Table from "antd/lib/table";
import "antd/lib/popconfirm/style";
import _Popconfirm from "antd/lib/popconfirm";
import "antd/lib/tooltip/style";
import _Tooltip from "antd/lib/tooltip";
import "antd/lib/icon/style";
import _Icon from "antd/lib/icon";
import "antd/lib/divider/style";
import _Divider from "antd/lib/divider";
import "antd/lib/button/style";
import _Button from "antd/lib/button";
import "antd/lib/input/style";
import _Input from "antd/lib/input";

var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
  return typeof obj;
} : function (obj) {
  return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
};

var _createClass = function () {
  function defineProperties(target, props) {
    for (var i = 0; i < props.length; i++) {
      var descriptor = props[i];
      descriptor.enumerable = descriptor.enumerable || false;
      descriptor.configurable = true;
      if ("value" in descriptor) descriptor.writable = true;
      Object.defineProperty(target, descriptor.key, descriptor);
    }
  }

  return function (Constructor, protoProps, staticProps) {
    if (protoProps) defineProperties(Constructor.prototype, protoProps);
    if (staticProps) defineProperties(Constructor, staticProps);
    return Constructor;
  };
}();

function _objectWithoutProperties(obj, keys) {
  var target = {};

  for (var i in obj) {
    if (keys.indexOf(i) >= 0) continue;
    if (!Object.prototype.hasOwnProperty.call(obj, i)) continue;
    target[i] = obj[i];
  }

  return target;
}

function _classCallCheck(instance, Constructor) {
  if (!(instance instanceof Constructor)) {
    throw new TypeError("Cannot call a class as a function");
  }
}

function _possibleConstructorReturn(self, call) {
  if (!self) {
    throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
  }

  return call && (typeof call === "object" || typeof call === "function") ? call : self;
}

function _inherits(subClass, superClass) {
  if (typeof superClass !== "function" && superClass !== null) {
    throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
  }

  subClass.prototype = Object.create(superClass && superClass.prototype, {
    constructor: {
      value: subClass,
      enumerable: false,
      writable: true,
      configurable: true
    }
  });
  if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
}

import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import "./style.less";

var sortAlphabetical = function sortAlphabetical(key) {
  return function (a, b) {
    a = a[key] || "";
    b = b[key] || "";
    return a.localeCompare(b);
  };
};

var sortNumeric = function sortNumeric(key) {
  return function (a, b) {
    return a[key] - b[key];
  };
};

var TableClientSide = function (_Component) {
  _inherits(TableClientSide, _Component);

  function TableClientSide() {
    var _ref;

    var _temp, _this, _ret;

    _classCallCheck(this, TableClientSide);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = _possibleConstructorReturn(this, (_ref = TableClientSide.__proto__ || Object.getPrototypeOf(TableClientSide)).call.apply(_ref, [this].concat(args))), _this), _this.state = {
      filteredInfo: null,
      sortedInfo: null,
      searchText: {}
    }, _this.filterRegExp = function (key, originalRenderFunction) {
      var filterValues = {
        filterDropdown: function filterDropdown(_ref2) {
          var setSelectedKeys = _ref2.setSelectedKeys,
              selectedKeys = _ref2.selectedKeys,
              confirm = _ref2.confirm,
              clearFilters = _ref2.clearFilters;
          return React.createElement("div", {
            className: "custom-filter-dropdown"
          }, React.createElement(_Input, {
            ref: function ref(ele) {
              return _this.searchInput = ele;
            },
            placeholder: "Search name",
            value: selectedKeys[0],
            onChange: function onChange(e) {
              return setSelectedKeys(e.target.value ? [e.target.value] : []);
            },
            onPressEnter: _this.handleSearch(selectedKeys[0], key, confirm)
          }), React.createElement(_Button, {
            type: "primary",
            onClick: _this.handleSearch(selectedKeys[0], key, confirm)
          }, "Cerca"), React.createElement(_Button, {
            onClick: _this.handleReset(key, clearFilters)
          }, "Reset"));
        },
        render: function render(text, record, index) {
          if (typeof originalRenderFunction === "function") {
            text = originalRenderFunction(text, record, index);
          }

          if (typeof text === "undefined" || !text) return "";
          var searchText = _this.state.searchText[key] ? _this.state.searchText[key] : "";
          return searchText ? React.createElement("span", null, text.split(new RegExp("(?<=" + searchText + ")|(?=" + searchText + ")", "i")).map(function (fragment, i) {
            return fragment.toLowerCase() === searchText.toLowerCase() ? React.createElement("span", {
              key: i,
              className: "highlight"
            }, fragment) : fragment;
          } // eslint-disable-line
          )) : text;
        },
        onFilter: function onFilter(value, record) {
          if (record[key]) {
            if (typeof record[key] !== "string") record[key] = record[key].toString();
            return record[key].toLowerCase().includes(value.toLowerCase());
          }

          return true;
        },
        onFilterDropdownVisibleChange: function onFilterDropdownVisibleChange(visible) {
          if (visible) {
            setTimeout(function () {
              _this.searchInput.focus();
            });
          }
        }
      };
      return filterValues;
    }, _this.handleSearch = function (selectedKeys, key, confirm) {
      return function () {
        confirm();

        _this.setState(function (oldState) {
          var newState = oldState;
          newState.searchText[key] = selectedKeys;
          return newState;
        });
      };
    }, _this.handleReset = function (key, clearFilters) {
      return function () {
        clearFilters();

        _this.setState(function (oldState) {
          var newState = oldState;
          delete newState.searchText[key];
          return newState;
        });
      };
    }, _this.setupColumns = function (columns) {
      var isActionDefined = false;
      var newColumns = columns.map(function (_ref3) {
        var _ref3$sorter = _ref3.sorter,
            sorter = _ref3$sorter === undefined ? {
          type: "alphabetical"
        } : _ref3$sorter,
            key = _ref3.key,
            _ref3$filter = _ref3.filter,
            filter = _ref3$filter === undefined ? {
          type: "regexp"
        } : _ref3$filter,
            render = _ref3.render,
            rest = _objectWithoutProperties(_ref3, ["sorter", "key", "filter", "render"]);

        var filterValues = [];
        if (key === "action" || _this.props.noAction) isActionDefined = true;

        if ((typeof sorter === "undefined" ? "undefined" : _typeof(sorter)) === "object" && sorter !== null) {
          if (sorter.type === "alphabetical") {
            if (typeof sorter.key !== "undefined") sorter = sortAlphabetical(sorter.key);else sorter = sortAlphabetical(key);
          }

          if (sorter.type === "numeric") {
            sorter = sortNumeric(key);
          }
        }

        if ((typeof filter === "undefined" ? "undefined" : _typeof(filter)) === "object" && filter !== null) {
          if (filter.type === "regexp") {
            if (typeof filter.key !== "undefined") {
              filterValues = _this.filterRegExp(filter.key, render);
            } else {
              filterValues = _this.filterRegExp(key, render);
            }
          }
        }

        return Object.assign({}, rest, {
          sorter: sorter,
          render: render
        }, filterValues);
      });

      if (!isActionDefined) {
        if (_this.props.onRowDelete && !_this.props.onRowEdit) {
          newColumns.push({
            title: "Azioni",
            key: "action",
            fixed: _this.props.notFixed ? null : "right",
            width: 120,
            render: function render(text, record) {
              return React.createElement("div", {
                className: "table__actionsMenu"
              }, React.createElement(_Divider, {
                type: "vertical"
              }), React.createElement(_Tooltip, {
                title: "Modifica l'elemento"
              }, React.createElement(Link, {
                to: "edit/" + encodeURIComponent(record["@id"])
              }, React.createElement(_Icon, {
                type: "edit"
              }))), React.createElement(_Divider, {
                type: "vertical"
              }), React.createElement(_Popconfirm, {
                title: "Sei sicuro di voler eliminare questo elemento",
                onConfirm: function onConfirm() {
                  _this.props.onRowDelete(record);
                },
                okText: "Si",
                cancelText: "No"
              }, React.createElement(_Icon, {
                type: "delete"
              })));
            }
          });
        } else if (_this.props.onRowEdit && _this.props.onRowDelete) {
          newColumns.push({
            title: "Azioni",
            key: "action",
            fixed: "right",
            width: 120,
            render: function render(text, record) {
              return React.createElement("div", {
                className: "table__actionsMenu"
              }, React.createElement(_Divider, {
                type: "vertical"
              }), React.createElement(_Icon, {
                onClick: function onClick() {
                  _this.props.onRowEdit(record);
                },
                type: "edit"
              }), React.createElement(_Divider, {
                type: "vertical"
              }), React.createElement(_Popconfirm, {
                title: "Sei sicuro di voler eliminare questo elemento",
                onConfirm: function onConfirm() {
                  _this.props.onRowDelete(record);
                },
                okText: "Si",
                cancelText: "No"
              }, React.createElement(_Icon, {
                type: "delete"
              })));
            }
          });
        } else if (_this.props.onRowEdit) {
          newColumns.push({
            title: "Azioni",
            key: "action",
            fixed: "right",
            width: 100,
            render: function render(text, record) {
              return React.createElement("div", {
                className: "table__actionsMenu"
              }, React.createElement(_Divider, {
                type: "vertical"
              }), React.createElement(_Icon, {
                onClick: function onClick() {
                  _this.props.onRowEdit(record);
                },
                type: "edit"
              }));
            }
          });
        } else {
          newColumns.push({
            title: "Azioni",
            key: "action",
            fixed: "right",
            width: 100,
            render: function render(text, record) {
              return React.createElement("div", null, React.createElement(_Divider, {
                type: "vertical"
              }), React.createElement(Link, {
                to: "edit/" + encodeURIComponent(record["@id"])
              }, React.createElement(_Icon, {
                type: "edit"
              })));
            }
          });
        }
      }

      return newColumns;
    }, _temp), _possibleConstructorReturn(_this, _ret);
  }

  _createClass(TableClientSide, [{
    key: "render",
    value: function render() {
      var _props = this.props,
          dataSource = _props.dataSource,
          columns = _props.columns,
          size = _props.size,
          remainingProps = _objectWithoutProperties(_props, ["dataSource", "columns", "size"]);

      return React.createElement(_Table, Object.assign({
        dataSource: dataSource,
        size: size,
        columns: this.setupColumns(columns)
      }, remainingProps));
    }
  }]);

  return TableClientSide;
}(Component);

export default TableClientSide;