import React, { Component } from "react";
import Form from "./Form";
import requireAuthentication from "../../_hoc/requireAuthentication";
import { connect } from "react-redux";
import { retrieve, update, reset } from "../../actions/color/update";
import UpdateModalType from "../../_components/modal/UpdateModalType";

class UpdateModal extends Component {
  render() {
    return (
      <UpdateModalType
        title={"Modifica " + this.props.iri}
        Form={Form}
        {...this.props}
      />
    );
  }
}

const mapStateToProps = state => ({
  retrieved: state.color.update.retrieved,
  retrieveError: state.color.update.retrieveError,
  retrieveLoading: state.color.update.retrieveLoading,
  updateError: state.color.update.updateError,
  updateLoading: state.color.update.updateLoading,
  eventSource: state.color.update.eventSource,
  created: state.color.create.created,
  deleted: state.color.del.deleted,
  updated: state.color.update.updated
});

const mapDispatchToProps = dispatch => ({
  retrieve: id => dispatch(retrieve(id)),
  update: (item, values) => dispatch(update(item, values)),
  reset: eventSource => dispatch(reset(eventSource))
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(requireAuthentication(UpdateModal));
