import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import PropTypes from "prop-types";
import Form from "./Form";
import { retrieve, update, reset } from "../../actions/commission/update";
import { del } from "../../actions/commission/delete";
import requireAuthentication from "../../_hoc/requireAuthentication";
import { Button } from "antd";
import { ModuleView } from "../../_layouts";

class Update extends Component {
  static propTypes = {
    retrieved: PropTypes.object,
    retrieveLoading: PropTypes.bool.isRequired,
    retrieveError: PropTypes.string,
    updateLoading: PropTypes.bool.isRequired,
    updateError: PropTypes.string,
    deleteLoading: PropTypes.bool.isRequired,
    deleteError: PropTypes.string,
    updated: PropTypes.object,
    deleted: PropTypes.object,
    eventSource: PropTypes.instanceOf(EventSource),
    retrieve: PropTypes.func.isRequired,
    update: PropTypes.func.isRequired,
    del: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired
  };

  componentDidMount() {
    this.props.retrieve(decodeURIComponent(this.props.match.params.id));
  }

  componentWillUnmount() {
    this.props.reset(this.props.eventSource);
  }

  render() {
    if (this.props.deleted) return <Redirect to=".." />;
    const item = this.props.updated ? this.props.updated : this.props.retrieved;
    return (
      <ModuleView
        title={"Modifica " + (item && item["@id"])}
        subTitle={"Modifica " + (item && item["@id"])}
        onBack={() => window.history.back()}
        loading={
          this.props.retrieveLoading ||
          this.props.updateLoading ||
          this.props.deleteLoading
        }
        errors={this.retrieveErrors()}
        notifications={this.retrieveNotifications()}
      >
        {item && (
          <Form
            onSubmit={values => this.props.update(item, values)}
            initialValues={item}
            extraButtons={
              <div style={{ display: "inline" }}>
                <Button type="danger" href="..">
                  Annulla
                </Button>
              </div>
            }
          />
        )}
      </ModuleView>
    );
  }

  del = () => {
    if (window.confirm("Sei sicuro di voler eliminare questo elemento?"))
      this.props.del(this.props.retrieved);
  };

  retrieveErrors = () => {
    var errors = [];
    if (this.props.retrieveError) {
      errors.push({
        message: "Errore durante il recupero dei dati",
        description: this.props.retrieveError
      });
    }
    if (this.props.updateError) {
      errors.push({
        message: "Errore durante l'aggiornamento",
        description: this.props.updateError
      });
    }
    if (this.props.deleteError) {
      errors.push({
        message: "Errore durante l'eliminazione",
        description: this.props.deleteError
      });
    }
    return errors;
  };

  retrieveNotifications = () => {
    var notifications = [];
    if (this.props.created) {
      notifications.push({
        message: this.props.created["@id"] + " creato."
      });
    }
    if (this.props.updated) {
      notifications.push({
        message: this.props.updated["@id"] + " aggiornato."
      });
    }
    return notifications;
  };
}

const mapStateToProps = state => ({
  retrieved: state.commission.update.retrieved,
  retrieveError: state.commission.update.retrieveError,
  retrieveLoading: state.commission.update.retrieveLoading,
  updateError: state.commission.update.updateError,
  updateLoading: state.commission.update.updateLoading,
  deleteError: state.commission.del.error,
  deleteLoading: state.commission.del.loading,
  eventSource: state.commission.update.eventSource,
  created: state.commission.create.created,
  deleted: state.commission.del.deleted,
  updated: state.commission.update.updated
});

const mapDispatchToProps = dispatch => ({
  retrieve: id => dispatch(retrieve(id)),
  update: (item, values) => dispatch(update(item, values)),
  del: item => dispatch(del(item)),
  reset: eventSource => dispatch(reset(eventSource))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(requireAuthentication(Update));
