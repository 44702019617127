import customer from "./customer";
import component from "./component";
import color from "./color";
import rawmaterial from "./rawmaterial";
import rawmaterialtype from "./rawmaterialtype";
import accessory from "./accessory";
import unitofmeasure from "./unitofmeasure";
import semifinished from "./semifinished";
import product from "./product";
import productvariant from "./productvariant";
import nation from "./nation";
import budget from "./budget";
import commission from "./commission";

export default {
  budget,
  customer,
  component,
  color,
  rawmaterial,
  rawmaterialtype,
  accessory,
  unitofmeasure,
  semifinished,
  product,
  productvariant,
  nation,
  commission
};
