import { layoutConstants } from "../_constants";

export function layout(
  state = {
    openKeys: [],
    isSiderOpened: true
  },
  action
) {
  switch (action.type) {
    case layoutConstants.SETOPENKEYS:
      return {
        openKeys: action.openKeys,
        isSiderOpened: state.isSiderOpened
      };
    case layoutConstants.TOGGLESIDERCOLLAPSE:
      return {
        isSiderOpened : !state.isSiderOpened,
        openKeys: state.openKeys
      }
    default:
      return state;
  }
}
