import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { list, reset } from "../../actions/customer/list";
import { TableClientSide } from "webtek-components";
import { ListMenu, ModuleView } from "../../_layouts";
import { del } from "../../actions/customer/delete";

class List extends Component {
  static propTypes = {
    retrieved: PropTypes.object,
    loading: PropTypes.bool.isRequired,
    error: PropTypes.string,
    eventSource: PropTypes.instanceOf(EventSource),
    deletedItem: PropTypes.object,
    list: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired
  };

  componentDidMount() {
    this.props.list(
      this.props.match.params.page &&
        decodeURIComponent(this.props.match.params.page)
    );
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.match.params.page !== nextProps.match.params.page)
      nextProps.list(
        nextProps.match.params.page &&
          decodeURIComponent(nextProps.match.params.page)
      );
  }

  componentWillUnmount() {
    this.props.reset(this.props.eventSource);
  }

  render() {
    const { loading, error, deleteLoading, deleteError } = this.props;
    return (
      <ModuleView
        type="list"
        title="Elenco Clienti"
        subTitle="Visualizza i clienti inseriti"
        loading={loading || deleteLoading}
        notifications={this.retrieveNotifications()}
        error={error || deleteError}
      >
        <ListMenu />
        <TableClientSide
          dataSource={this.retrieveDataSource()}
          columns={this.retrieveColumns()}
          onRowDelete={this.deleteEntity}
          scroll={{ x: 1300 }}
        />
      </ModuleView>
    );
  }

  deleteEntity = entityValues => {
    this.props.del(entityValues).then(this.reload);
  };

  reload = () => {
    this.props.list(
      this.props.match.params.page &&
        decodeURIComponent(this.props.match.params.page)
    );
  };

  retrieveNotifications = () => {
    var notifications = [];
    if (this.props.deletedItem) {
      notifications.push({
        message: this.props.deletedItem["@id"] + " eliminato."
      });
    }
    return notifications;
  };

  retrieveColumns() {
    return [
      {
        title: "Codice",
        key: "code",
        dataIndex: "code"
      },
      {
        title: "Tipo",
        key: "type",
        dataIndex: "type",
        filter: {
          type: "custom"
        },
        filters: [
          {
            text: "Azienda",
            value: "Azienda"
          },
          {
            text: "Privato",
            value: "Privato"
          }
        ],
        filterMultiple: false,
        onFilter: (value, record) => {
          console.log(value, record);
          return record.type.indexOf(value) === 0;
        }
      },
      {
        title: "Nome",
        key: "name",
        dataIndex: "name"
      },
      // {
      //   title: "Cognome",
      //   key: "surname",
      //   dataIndex: "surname"
      // },
      {
        title: "Cod. Fiscale",
        key: "codiceFiscale",
        dataIndex: "codiceFiscale"
      },
      {
        title: "Rag. Sociale",
        key: "ragioneSociale",
        dataIndex: "ragioneSociale"
      },
      {
        title: "P. IVA",
        key: "partitaIva",
        dataIndex: "partitaIva"
      },
      {
        title: "Telefono",
        key: "telephone",
        dataIndex: "telephone"
      },
      {
        title: "Email",
        key: "email",
        dataIndex: "email"
      },
      {
        title: "Indirizzo",
        key: "address.address",
        dataIndex: "address.address"
      }
    ];
  }

  retrieveDataSource() {
    if (this.props.retrieved && this.props.retrieved["hydra:member"]) {
      return this.props.retrieved["hydra:member"].map(item => ({
        key: item["@id"],
        "@id": item["@id"],
        id: item["@id"],
        code: item["code"],
        type: item["type"] === 1 ? "Privato" : "Azienda",
        name: item["name"] ? item["name"] + " " + item["surname"] : "",
        surname: item["surname"],
        codiceFiscale: item["codiceFiscale"],
        ragioneSociale: item["ragioneSociale"],
        partitaIva: item["partitaIva"],
        codiceDestinatario: item["codiceDestinatario"],
        pec: item["pec"],
        telephone: item["telephone"],
        fax: item["fax"],
        mobile: item["mobile"],
        website: item["website"],
        email: item["email"],
        notes: item["notes"],
        "address.address": item["address"]
          ? item["address"]["address"] +
            " " +
            item["address"]["cap"] +
            " " +
            item["address"]["city"]
          : null,
        createdAt: item["createdAt"],
        updatedAt: item["updatedAt"]
      }));
    }
    return [];
  }
}

const mapStateToProps = state => {
  const { retrieved, loading, error, eventSource } = state.customer.list;
  const deletedItem = state.customer.del.deleted;
  return {
    retrieved,
    loading,
    error,
    eventSource,
    deletedItem,
    deleteError: state.color.del.error,
    deleteLoading: state.color.del.loading
  };
};

const mapDispatchToProps = dispatch => ({
  list: page => dispatch(list(page)),
  reset: eventSource => dispatch(reset(eventSource)),
  del: item => dispatch(del(item))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(List);
