import React, { Component } from "react";
import { Alert, PageHeader } from "antd";
// import "./style.less";

export default class ModuleView extends Component {
  loadingAlert = () => {
    return (
      this.props.loading && (
        <Alert message="Caricamento..." type="info" showIcon />
      )
    );
  };

  deletionAlert = () => {
    return (
      this.props.deletedItem && (
        <Alert
          message={this.props.deletedItem["@id"] + " eliminato."}
          type="success"
          showIcon
          closable
        />
      )
    );
  };

  errorAlert = () => {
    return (
      this.props.error && (
        <Alert
          message="Errore"
          type="error"
          description={this.props.error}
          showIcon
          closable
        />
      )
    );
  };

  generateErrorsAlerts = () => {
    if (this.props.errors) {
      return this.props.errors.map((error, index) => (
        <Alert
          key={"error_" + index}
          message={error.message}
          description={error.description}
          type="error"
          showIcon
          closable
        />
      ));
    }
    return null;
  };

  generateSuccessAlerts = () => {
    if (this.props.notifications) {
      return this.props.notifications.map((notification, index) => (
        <Alert
          key={"notification_" + index}
          message={notification.message}
          description={notification.description}
          type="success"
          showIcon
          closable
        />
      ));
    }
    return null;
  };

  render() {
    const { type = null, children, title, subTitle, onBack } = this.props;
    var className = "moduleView";
    if (type === "list") className += " moduleView--isList";

    return (
      <div className={className}>
        <PageHeader
          className="moduleView__header"
          title={title}
          subTitle={subTitle}
          onBack={onBack}
        />
        <div className="moduleView__content">
          {this.loadingAlert()}
          {this.deletionAlert()}
          {this.errorAlert()}
          {this.generateErrorsAlerts()}
          {this.generateSuccessAlerts()}
          {children}
        </div>
      </div>
    );
  }
}
