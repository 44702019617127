import React, { Component } from "react";
import { Table, Button, Form, Popconfirm, Input } from "antd";
import ProductVariantForm from "../forms/ProductVariantForm";
import {
  DecimalNormalizer,
  QuantityFormatter,
  CurrencyFormatter
} from "../../_services/field_formatters";

const EditableContext = React.createContext();

const EditableRow = ({ form, index, ...props }) => (
  <EditableContext.Provider value={form}>
    <tr {...props} />
  </EditableContext.Provider>
);

const EditableFormRow = Form.create()(EditableRow);

class CurrencyInput extends Component {
  focus() {
    this.input.focus();
  }

  render() {
    const { value, onChange, onPressEnter, onBlur } = this.props;
    return (
      <Input
        ref={input => {
          this.input = input;
        }}
        onPressEnter={onPressEnter}
        onBlur={onBlur}
        onChange={event => {
          onChange(DecimalNormalizer(event.target.value));
        }}
        value={CurrencyFormatter(value)}
      />
    );
  }
}

class QuantityInput extends Component {
  focus() {
    this.input.focus();
  }

  render() {
    const { value, onChange, onPressEnter, onBlur } = this.props;
    return (
      <Input
        ref={input => {
          this.input = input;
        }}
        onPressEnter={onPressEnter}
        onBlur={onBlur}
        onChange={event => {
          onChange(DecimalNormalizer(event.target.value));
        }}
        value={QuantityFormatter(value)}
      />
    );
  }
}

class EditableCell extends React.Component {
  state = {
    editing: false
  };

  toggleEdit = () => {
    const editing = !this.state.editing;
    this.setState({ editing }, () => {
      if (editing) {
        this.input.focus();
      }
    });
  };

  save = e => {
    const { record, handleSave, type } = this.props;
    this.form.validateFields((error, values) => {
      if (error && error[e.currentTarget.id]) {
        return;
      }
      this.toggleEdit();
      type === "currency"
        ? (values.price = values.price.toString())
        : (values.quantity = values.quantity.toString());
      handleSave({ ...record, ...values }, this.props.indice);
    });
  };

  render() {
    const { editing } = this.state;
    const {
      editable,
      dataIndex,
      title,
      record,
      index,
      handleSave,
      type,
      ...restProps
    } = this.props;

    return (
      <td {...restProps}>
        {editable ? (
          <EditableContext.Consumer>
            {form => {
              this.form = form;
              return editing ? (
                <Form.Item style={{ margin: 0 }}>
                  {form.getFieldDecorator(dataIndex, {
                    rules: [
                      {
                        required: true,
                        message:
                          "Devi inserire un valore numerico maggiore o uguale a 0",
                        min: 0,
                        type: "string"
                      }
                    ],
                    initialValue: record[dataIndex]
                  })(
                    type === "currency" ? (
                      <CurrencyInput
                        ref={node => (this.input = node)}
                        onPressEnter={this.save}
                        onBlur={this.save}
                      />
                    ) : (
                      <QuantityInput
                        ref={node => (this.input = node)}
                        onPressEnter={this.save}
                        onBlur={this.save}
                      />
                    )
                  )}
                </Form.Item>
              ) : (
                <div
                  className="editable-cell-value-wrap"
                  style={{ paddingRight: 24 }}
                  onClick={this.toggleEdit}
                >
                  {restProps.children}
                </div>
              );
            }}
          </EditableContext.Consumer>
        ) : (
          restProps.children
        )}
      </td>
    );
  }
}

class ProductVariantAssociation extends Component {
  state = { visible: false, onEditVariant: null };

  retrieveDataSource = () => {
    const { fields } = this.props;
    return fields.length
      ? fields.map((name, index, values) => {
          var current = values.get(index);
          return {
            key: current.code,
            code: current.code,
            cost: current.cost,
            index: index,
            name: current.name,
            barcode: current.barcode,
            price: current.price,
            quantity: current.quantity
          };
        })
      : [];
  };

  showModal = () => {
    this.setState({
      visible: true,
      onEditVariant: null
    });
  };

  showEditModal = fieldIndex => {
    this.setState({
      visible: true,
      onEditVariant: {
        values: this.props.fields.get(fieldIndex),
        index: fieldIndex
      }
    });
  };

  calcRelativeCost = (costPerKg, percentage) => {
    return (Math.round(costPerKg * (percentage / 100) * 10000) / 10000).toFixed(
      4
    );
  };

  calcProductVariantCost = (semifinishedsQuantities, accessoriesQuantities) => {
    var total = 0;
    semifinishedsQuantities.forEach(
      semifinishedQuantity =>
        (total += parseFloat(semifinishedQuantity.costRelative))
    );
    accessoriesQuantities.forEach(
      accessoryQuantity => (total += parseFloat(accessoryQuantity.costRelative))
    );
    return total.toString();
  };
  handleCreate = e => {
    const form = this.formRef.props.form;
    const { fields } = this.props;
    form.validateFields(
      (
        err,
        {
          code,
          name,
          barcode,
          price,
          quantity,
          semifinishedsQuantities,
          accessoriesQuantities
        }
      ) => {
        if (err) {
          return;
        }
        fields.push({
          key: code,
          code: code,
          name: name,
          barcode: barcode,
          quantity: quantity,
          price: price,
          cost: this.calcProductVariantCost(
            semifinishedsQuantities,
            accessoriesQuantities
          ),
          semifinishedsQuantities: semifinishedsQuantities,
          accessoriesQuantities: accessoriesQuantities
        });
        form.resetFields();
        this.setState({ visible: false });
      }
    );
  };

  handleEdit = e => {
    const form = this.formRef.props.form;
    const { fields } = this.props;
    form.validateFields(
      (
        err,
        {
          code,
          name,
          barcode,
          price,
          quantity,
          semifinishedsQuantities,
          accessoriesQuantities
        }
      ) => {
        if (err) {
          return;
        }
        fields.splice(this.state.onEditVariant.index, 1, {
          ...fields.get(this.state.onEditVariant.index),
          key: code,
          code: code,
          name: name,
          barcode: barcode,
          quantity: quantity,
          price: price,
          cost: this.calcProductVariantCost(
            semifinishedsQuantities,
            accessoriesQuantities
          ),
          semifinishedsQuantities: semifinishedsQuantities,
          accessoriesQuantities: accessoriesQuantities
        });
        form.resetFields();
        this.setState({ visible: false, onEditVariant: null });
      }
    );
  };

  handleSave = ({ index, price, quantity }) => {
    const { fields } = this.props;
    const oldData = fields.get(index);
    fields.splice(index, 1, {
      ...oldData,
      price: price.toString(),
      quantity: quantity.toString()
    });
  };

  saveFormRef = formRef => {
    this.formRef = formRef;
  };

  handleCancel = e => {
    this.setState({
      visible: false
    });
  };

  retrieveColumns() {
    const { fields } = this.props;
    return [
      {
        title: "Codice",
        key: "code",
        dataIndex: "code"
      },
      {
        title: "Nome",
        key: "name",
        dataIndex: "name"
      },
      {
        title: "Cod.barre",
        key: "barcode",
        dataIndex: "barcode"
      },
      {
        title: "Prezzo",
        key: "price",
        dataIndex: "price",
        editable: true,
        onCell: record => ({
          record,
          editable: true,
          type: "currency",
          dataIndex: "price",
          title: "Prezzo",
          handleSave: this.handleSave
        }),
        render: CurrencyFormatter
      },
      {
        title: "Costo",
        key: "cost",
        dataIndex: "cost",
        render: CurrencyFormatter
      },
      {
        title: "Quantità",
        key: "quantity",
        dataIndex: "quantity",
        editable: true,
        render: QuantityFormatter,
        onCell: record => ({
          record,
          editable: true,
          dataIndex: "quantity",
          type: "quantity",
          title: "Quantità",
          handleSave: this.handleSave
        })
      },
      {
        title: "Azioni",
        key: "action",
        render: (text, record) => (
          <div>
            <Popconfirm
              title="Sei sicuro?"
              onConfirm={() => fields.remove(record.index)}
            >
              <Button icon="delete">Elimina</Button>
            </Popconfirm>
            <Button
              onClick={() => this.showEditModal(record.index)}
              icon="edit"
            >
              Modifica
            </Button>
          </div>
        )
      }
    ];
  }

  render() {
    var {
      fields,
      meta,
      children,
      hasFeedback,
      label,
      help,
      semifinished = [],
      accessories = [],
      ...rest
    } = this.props;
    const hasError = meta.error && meta.invalid;
    const components = {
      body: {
        row: EditableFormRow,
        cell: EditableCell
      }
    };
    if (hasError) {
      help = hasError && meta.error;
    }
    return (
      <Form.Item
        colon={false}
        validateStatus={hasError ? "error" : "success"}
        hasFeedback={hasFeedback && hasError}
        help={help}
      >
        <div className="associationsTableMenu">
          <Button onClick={this.showModal}>Aggiungi Variante</Button>
        </div>
        <ProductVariantForm
          wrappedComponentRef={this.saveFormRef}
          visible={this.state.visible}
          onCancel={this.handleCancel}
          onCreate={
            this.state.onEditVariant ? this.handleEdit : this.handleCreate
          }
          semifinished={semifinished}
          accessories={accessories}
          values={
            this.state.onEditVariant ? this.state.onEditVariant.values : null
          }
        />
        <Table
          components={components}
          rowClassName={() => "editable-row"}
          columns={this.retrieveColumns()}
          {...rest}
          // onChange={input.onChange}
          // onDragStart={input.onDragStart}
          // onDrop={input.onDrop}
          // onFocus={input.onFocus}
          dataSource={this.retrieveDataSource()}
          // value={input.value}
          // children={children}
        />
      </Form.Item>
    );
  }
}

export default ProductVariantAssociation;
