import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Layout } from "antd";
import customRoutes from "./_helpers/routes";
import { connect } from "react-redux";
import { Route, Switch } from "react-router-dom";
import { LocaleProvider } from "antd";
import itIT from "antd/lib/locale-provider/it_IT";
import { LeftMenu } from "./_layouts";
import { Header } from "./_layouts";
import VersionBox from "./_components/versionBox";
const { Content } = Layout;
//import "./styles/index.less";

class App extends Component {
  render() {
    return (
      <LocaleProvider locale={itIT}>
        <Layout className="webtek-skeleton" style={{ minHeight: "100vh" }}>
          <Header />
          <Layout>
            <LeftMenu />
            <Content
              className={
                this.props.isSiderOpened
                  ? "content-container"
                  : "content-container content-container--siderClosed"
              }
              style={{ margin: "0 16px" }}
            >
              <Switch>
                {customRoutes}
                <Route render={() => <h1>Not Found</h1>} />
              </Switch>
            </Content>
          </Layout>
          <VersionBox />
        </Layout>
      </LocaleProvider>
    );
  }
}

const mapStateToProps = ({ layout }) => ({
  isSiderOpened: layout.isSiderOpened
});

export default withRouter(connect(mapStateToProps)(App));
