import React, { Component } from "react";
import "./style.less";
import { appName, version } from "../../config/_general";

class VersionBox extends Component {
  render() {
    return (
      <div className="versionBox">
        {appName} ver. {version} <br /> Webtek Spa
      </div>
    );
  }
}

export default VersionBox;
