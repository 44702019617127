import "antd/lib/menu/style";
import _Menu from "antd/lib/menu";
import "antd/lib/icon/style";
import _Icon from "antd/lib/icon";

function _objectWithoutProperties(obj, keys) {
  var target = {};

  for (var i in obj) {
    if (keys.indexOf(i) >= 0) continue;
    if (!Object.prototype.hasOwnProperty.call(obj, i)) continue;
    target[i] = obj[i];
  }

  return target;
}

import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

var Item = function Item(_ref) {
  var key = _ref.key,
      route = _ref.route,
      icon = _ref.icon,
      label = _ref.label,
      rest = _objectWithoutProperties(_ref, ["key", "route", "icon", "label"]);

  return React.createElement(_Menu.Item, Object.assign({
    key: key
  }, rest), React.createElement(Link, {
    to: route
  }, React.createElement(_Icon, {
    type: icon
  }), React.createElement("span", null, label)));
};

export default Item;