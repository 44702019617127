import React, { Component } from "react";
import { Field, reduxForm, FieldArray } from "redux-form";
import PropTypes from "prop-types";
import { Form, Card } from "antd";
import { translate } from "../../_translations/product";
import {
  renderField,
  ColorSelectField,
  SemifinishedSelectField,
  AccessorySelectField
} from "../../_components/fields";
// import {
//   CurrencyFormatter,
//   QuantityFormatter,
//   DecimalNormalizer
// } from "../../_services/field_formatters";
import ProductVariantAssociation from "../../_components/associations/ProductVariantAssociation";
import { formValues } from "redux-form"; // ES6
import { FormButtonsContainer } from "../../_layouts";

class FormTek extends Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    error: PropTypes.string
  };

  render() {
    const {
      handleSubmit,
      pristine,
      reset,
      submitting,
      handleDelete,
      extraButtons
    } = this.props;

    const Prova = formValues("semifinished", "accessories")(
      ProductVariantAssociation
    );

    return (
      <Form layout="vertical" onSubmit={handleSubmit}>
        <Card title="Informazioni">
          <Field
            component={renderField}
            name="name"
            label={translate("name")}
            type="text"
            placeholder="Prodotto"
            required={true}
          />
          <Field
            component={renderField}
            name="description"
            label={translate("description")}
            type="longtext"
            placeholder="Descrizione del prodotto"
          />
          <Field
            component={ColorSelectField}
            name="color"
            label={translate("color")}
            type="text"
            placeholder=""
          />
          <Field
            component={renderField}
            name="pieces"
            label={translate("pieces")}
            type="number"
            placeholder="1"
            normalize={v => parseFloat(v)}
          />
        </Card>
        <Card title="Composizione del prodotto">
          <Field
            component={SemifinishedSelectField}
            mode="multiple"
            name="semifinished"
            label="Semilavorati"
            type="text"
            placeholder=""
          />
          <Field
            component={AccessorySelectField}
            mode="multiple"
            name="accessories"
            label="Accessori"
            type="text"
            placeholder=""
          />
        </Card>
        <Card title="Varianti">
          <FieldArray
            rerenderOnEveryChange={true}
            component={Prova}
            name="variants"
          />
        </Card>

        <FormButtonsContainer
          extraButtons={extraButtons}
          disabled={pristine || submitting}
          handleDelete={handleDelete}
          reset={reset}
        />
      </Form>
    );
  }
}

export default reduxForm({
  form: "product",
  enableReinitialize: true,
  keepDirtyOnReinitialize: true
})(FormTek);
