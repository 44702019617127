import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { list, reset } from "../../actions/semifinished/list";
import { TableClientSide } from "webtek-components";
import { ListMenu, ModuleView } from "../../_layouts";
import { translate } from "../../_translations/semifinished";
import { CurrencyFormatter } from "../../_services/field_formatters";
import { del } from "../../actions/semifinished/delete";
import {
  update,
  reset as resetUpdate
} from "../../actions/semifinished/update";
import {
  EditableFormRow,
  EditableCell
} from "../../_components/table/EditableTable";

class List extends Component {
  static propTypes = {
    retrieved: PropTypes.object,
    loading: PropTypes.bool.isRequired,
    error: PropTypes.string,
    eventSource: PropTypes.instanceOf(EventSource),
    deletedItem: PropTypes.object,
    list: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired
  };

  componentDidMount() {
    this.props.list(
      this.props.match.params.page &&
        decodeURIComponent(this.props.match.params.page)
    );
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.match.params.page !== nextProps.match.params.page)
      nextProps.list(
        nextProps.match.params.page &&
          decodeURIComponent(nextProps.match.params.page)
      );
  }

  componentWillUnmount() {
    this.props.reset(this.props.eventSource);
  }

  render() {
    const { loading, error } = this.props;
    const components = {
      body: {
        row: EditableFormRow,
        cell: EditableCell
      }
    };
    return (
      <ModuleView
        type="list"
        title="Elenco semilavorati"
        subTitle="Visualizza i semilavorati inseriti"
        loading={loading}
        notifications={this.retrieveNotifications()}
        error={error}
        errors={this.retrieveErrors()}
      >
        <ListMenu />
        <TableClientSide
          rowClassName={() => "editable-row"}
          components={components}
          dataSource={this.retrieveDataSource()}
          columns={this.retrieveColumns()}
          scroll={{ x: 1000 }}
          onRowDelete={this.deleteEntity}
        />
      </ModuleView>
    );
  }

  deleteEntity = entityValues => {
    this.props.del(entityValues).then(this.reload);
  };
  reload = () => {
    this.props.list(
      this.props.match.params.page &&
        decodeURIComponent(this.props.match.params.page)
    );
  };

  retrieveErrors = () => {
    var errors = [];
    if (this.props.updateError) {
      errors.push({
        message: "Errore durante l'aggiornamento",
        description: this.props.updateError
      });
    }
    if (this.props.deleteError) {
      errors.push({
        message: "Errore durante l'eliminazione",
        description: this.props.deleteError
      });
    }
    return errors;
  };

  retrieveColumns() {
    return [
      {
        title: translate("name"),
        key: "name",
        dataIndex: "name",
        editable: true,
        onCell: record => ({
          record,
          editable: true,
          dataIndex: "name",
          title: "Nome",
          handleSave: this.handleSave
        })
      },
      {
        title: translate("color"),
        key: "color",
        dataIndex: "color"
      },
      {
        title: translate("costPerKg"),
        key: "costPerKg",
        dataIndex: "costPerKg",
        sorter: {
          type: "numeric"
        },
        render: CurrencyFormatter
      },
      // {
      //   title: translate("quantity"),
      //   key: "quantity",
      //   dataIndex: "quantity",
      //   sorter: {
      //     type: "numeric"
      //   },
      //   editable: true,
      //   onCell: record => ({
      //     record,
      //     editable: true,
      //     dataIndex: "quantity",
      //     title: "Quantità",
      //     handleSave: this.handleSave,
      //     editComponent: QuantityInput
      //   }),
      //   render: value => {
      //     return QuantityFormatter(value) + " g";
      //   }
      // },
      {
        title: "Materie prime",
        key: "rawMaterialsPercentage",
        dataIndex: "rawMaterialsPercentage",
        sorter: null,
        filter: null,
        render: (records, rowData) => {
          var links = records.map(({ rawMaterial, percentage }, index) => {
            return (
              <span key={rawMaterial["@id"]}>
                <Link
                  to={`../raw_materials/edit/${encodeURIComponent(
                    rawMaterial["@id"]
                  )}`}
                >
                  {rawMaterial.name}
                </Link>
                {" " + percentage + "%"}
                {index !== records.length - 1 ? ", " : ""}
              </span>
            );
          });
          return links;
        }
      }
    ];
  }

  retrieveDataSource() {
    if (this.props.retrieved && this.props.retrieved["hydra:member"]) {
      return this.props.retrieved["hydra:member"].map(item => {
        return {
          key: item["@id"],
          "@id": item["@id"],
          id: item["@id"],
          name: item["name"],
          color: item["color"] ? item["color"]["name"] : null,
          costPerKg: item["costPerKg"],
          // quantity: item["quantity"],
          rawMaterialsPercentage: item["rawMaterialsPercentage"]
        };
      });
    }
    return [];
  }

  handleSave = values => {
    const { update } = this.props;
    update(values, {
      quantity: values.quantity,
      name: values.name
    }).then(() => {
      this.reload();
      this.props.resetUpdate();
    });
  };
  retrieveNotifications = () => {
    var notifications = [];
    if (this.props.created) {
      notifications.push({
        message: this.props.created["@id"] + " creato."
      });
    }
    if (this.props.updated) {
      notifications.push({
        message: this.props.updated["@id"] + " aggiornato."
      });
    }
    if (this.props.deletedItem) {
      notifications.push({
        message: this.props.deletedItem["@id"] + " eliminato."
      });
    }
    return notifications;
  };

  renderLinks = (type, items) => {
    if (Array.isArray(items)) {
      return items.map((item, i) => (
        <div key={i}>{this.renderLinks(type, item)}</div>
      ));
    }

    return (
      <Link to={`../${type}/show/${encodeURIComponent(items)}`}>{items}</Link>
    );
  };
}

const mapStateToProps = state => {
  const { retrieved, loading, error, eventSource } = state.semifinished.list;
  const deletedItem = state.semifinished.del.deleted;
  return {
    retrieved,
    loading,
    error,
    eventSource,
    deletedItem,
    deleteError: state.semifinished.del.error,
    deleteLoading: state.semifinished.del.loading,
    updateError: state.semifinished.update.updateError,
    updateLoading: state.semifinished.update.updateLoading,
    updated: state.semifinished.update.updated
  };
};

const mapDispatchToProps = dispatch => ({
  list: page => dispatch(list(page)),
  update: (item, values) => dispatch(update(item, values)),
  del: item => dispatch(del(item)),
  reset: eventSource => dispatch(reset(eventSource)),
  resetUpdate: eventSource => dispatch(resetUpdate(eventSource))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(List);
