import "antd/lib/row/style";
import _Row from "antd/lib/row";
import "antd/lib/button/style";
import _Button from "antd/lib/button";
import "antd/lib/input/style";
import _Input from "antd/lib/input";
import "antd/lib/form/style";
import _Form from "antd/lib/form";
import React from "react";
import PropTypes from "prop-types"; // import config from "../utils/config";
// import { userActions } from "../_actions";

import "./style.less";
var FormItem = _Form.Item;
export var LoginForm = function LoginForm(_ref) {
  var startLogin = _ref.startLogin,
      loading = _ref.loading,
      logo = _ref.logo,
      _ref$name = _ref.name,
      name = _ref$name === undefined ? "Webtek Admin" : _ref$name,
      _ref$usernameError = _ref.usernameError,
      usernameError = _ref$usernameError === undefined ? "Devi inserire il nome utente" : _ref$usernameError,
      _ref$passwordError = _ref.passwordError,
      passwordError = _ref$passwordError === undefined ? "Devi inserire una password valida " : _ref$passwordError,
      error = _ref.error,
      _ref$form = _ref.form,
      getFieldDecorator = _ref$form.getFieldDecorator,
      validateFieldsAndScroll = _ref$form.validateFieldsAndScroll,
      _ref$background = _ref.background,
      background = _ref$background === undefined ? "white" : _ref$background;

  function handleOk() {
    validateFieldsAndScroll(function (errors, values) {
      if (errors) {
        return;
      }

      if (startLogin && typeof startLogin === "function") {
        startLogin(values.username, values.password);
      }
    });
  }

  return React.createElement("div", {
    className: "form",
    style: {
      background: background
    }
  }, error && React.createElement("p", null, " ", error, " "), React.createElement("div", {
    className: "logo"
  }, logo && React.createElement("img", {
    alt: "logo",
    src: logo
  })), React.createElement("form", null, React.createElement(FormItem, {
    hasFeedback: true
  }, getFieldDecorator("username", {
    rules: [{
      required: true,
      message: usernameError
    }]
  })(React.createElement(_Input, {
    onPressEnter: handleOk,
    placeholder: "Username"
  }))), React.createElement(FormItem, {
    hasFeedback: true
  }, getFieldDecorator("password", {
    rules: [{
      required: true,
      message: passwordError
    }]
  })(React.createElement(_Input, {
    type: "password",
    onPressEnter: handleOk,
    placeholder: "Password"
  }))), React.createElement(_Row, null, React.createElement(_Button, {
    type: "primary",
    onClick: handleOk,
    loading: loading
  }, "Accedi"))));
};
export default _Form.create()(LoginForm);