import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { list, reset } from "../../actions/product/list";
import { TableClientSide } from "webtek-components";
import { ListMenu, ModuleView } from "../../_layouts";
import { translate } from "../../_translations/product";
import { del } from "../../actions/product/delete";
import { update } from "../../actions/product/update";
import {
  EditableFormRow,
  EditableCell
} from "../../_components/table/EditableTable";
import ProductVariantTable from "./ProductVariantTable";

class List extends Component {
  static propTypes = {
    retrieved: PropTypes.object,
    loading: PropTypes.bool.isRequired,
    error: PropTypes.string,
    eventSource: PropTypes.instanceOf(EventSource),
    deletedItem: PropTypes.object,
    list: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired
  };

  componentDidMount() {
    this.props.list(
      this.props.match.params.page &&
        decodeURIComponent(this.props.match.params.page)
    );
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.match.params.page !== nextProps.match.params.page)
      nextProps.list(
        nextProps.match.params.page &&
          decodeURIComponent(nextProps.match.params.page)
      );
  }

  componentWillUnmount() {
    this.props.reset(this.props.eventSource);
  }

  expandedRowRender = ({ variants }) => {
    return <ProductVariantTable reload={this.reload} dataSource={variants} />;
  };

  render() {
    const { loading, error } = this.props;
    const components = {
      body: {
        row: EditableFormRow,
        cell: EditableCell
      }
    };
    return (
      <ModuleView
        type="list"
        title="Elenco prodotti"
        subTitle="Visualizza i prodotti inseriti"
        loading={loading}
        notifications={this.retrieveNotifications()}
        error={error}
        errors={this.retrieveErrors()}
      >
        <ListMenu />
        <TableClientSide
          rowClassName={() => "editable-row"}
          components={components}
          dataSource={this.retrieveDataSource()}
          columns={this.retrieveColumns()}
          notFixed={true}
          // scroll={{ x: 1300 }}
          onRowDelete={this.deleteEntity}
          expandedRowRender={this.expandedRowRender}
        />
      </ModuleView>
    );
  }

  retrieveColumns() {
    return [
      {
        title: "id",
        key: "id",
        dataIndex: "id"
      },
      {
        title: translate("name"),
        key: "name",
        dataIndex: "name",
        editable: true,
        onCell: record => ({
          record,
          editable: true,
          dataIndex: "name",
          title: "Nome",
          handleSave: this.handleSave
        })
      },
      // {
      //   title: translate("description"),
      //   key: "description",
      //   dataIndex: "description"
      // },
      {
        title: translate("color"),
        key: "color.name",
        dataIndex: "color.name"
      },
      {
        title: translate("semifinished"),
        key: "semifinished",
        dataIndex: "semifinished",
        filter: null,
        sorter: null,
        render: (records, rowData) => {
          var links = records.map((semifinished, index) => {
            return (
              <span key={semifinished["@id"]}>
                <Link
                  to={`../semifinisheds/edit/${encodeURIComponent(
                    semifinished["@id"]
                  )}`}
                >
                  {semifinished.name}
                </Link>
                {index !== records.length - 1 ? ", " : ""}
              </span>
            );
          });
          return links;
        }
      },
      {
        title: translate("accessories"),
        key: "accessories",
        dataIndex: "accessories",
        filter: null,
        sorter: null,
        render: (records, rowData) => {
          var links = records.map((accessory, index) => {
            return (
              <span key={accessory["@id"]}>
                <Link
                  to={`../accessories/edit/${encodeURIComponent(
                    accessory["@id"]
                  )}`}
                >
                  {accessory.name}
                </Link>
                {index !== records.length - 1 ? ", " : ""}
              </span>
            );
          });
          return links;
        }
      }
    ];
  }

  handleSave = values => {
    const { update } = this.props;
    update(values, {
      name: values.name
    }).then(this.reload);
  };

  retrieveNotifications = () => {
    var notifications = [];
    if (this.props.created) {
      notifications.push({
        message: this.props.created["@id"] + " creato."
      });
    }
    if (this.props.updated) {
      notifications.push({
        message: this.props.updated["@id"] + " aggiornato."
      });
    }
    if (this.props.variantUpdated) {
      notifications.push({
        message: this.props.variantUpdated["@id"] + " aggiornato."
      });
    }
    if (this.props.deletedItem) {
      notifications.push({
        message: this.props.deletedItem["@id"] + " eliminato."
      });
    }
    return notifications;
  };

  retrieveErrors = () => {
    var errors = [];
    if (this.props.updateError) {
      errors.push({
        message: "Errore durante l'aggiornamento",
        description: this.props.updateError
      });
    }
    if (this.props.deleteError) {
      errors.push({
        message: "Errore durante l'eliminazione",
        description: this.props.deleteError
      });
    }
    return errors;
  };

  deleteEntity = entityValues => {
    this.props.del(entityValues).then(this.reload);
  };
  reload = () => {
    this.props.list(
      this.props.match.params.page &&
        decodeURIComponent(this.props.match.params.page)
    );
  };

  retrieveDataSource() {
    if (this.props.retrieved && this.props.retrieved["hydra:member"]) {
      return this.props.retrieved["hydra:member"].map(item => {
        return {
          key: item["@id"],
          id: item["@id"],
          "@id": item["@id"],
          name: item["name"],
          description: item["description"],
          color: item["color"],
          pieces: item["pieces"],
          semifinished: item["semifinished"],
          accessories: item["accessories"],
          variants: item["variants"]
        };
      });
    }
    return [];
  }

  renderLinks = (type, items) => {
    if (Array.isArray(items)) {
      return items.map((item, i) => (
        <div key={i}>{this.renderLinks(type, item)}</div>
      ));
    }

    return (
      <Link to={`../${type}/edit/${encodeURIComponent(items)}`}>{items}</Link>
    );
  };
}

const mapStateToProps = state => {
  const { retrieved, loading, error, eventSource } = state.product.list;
  const deletedItem = state.product.del.deleted;
  return {
    retrieved,
    loading,
    error,
    eventSource,
    deletedItem,
    deleteError: state.product.del.error,
    deleteLoading: state.product.del.loading,
    updateError: state.product.update.updateError,
    updateLoading: state.product.update.updateLoading,
    updated: state.product.update.updated,
    variantUpdated: state.productvariant.update.updated
  };
};

const mapDispatchToProps = dispatch => ({
  list: page => dispatch(list(page)),
  reset: eventSource => dispatch(reset(eventSource)),
  update: (item, values) => dispatch(update(item, values)),
  del: item => dispatch(del(item))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(List);
