import React, { Component } from "react";
import { Field, reduxForm, FieldArray } from "redux-form";
import PropTypes from "prop-types";
import { Form, Card, Row, Col } from "antd";
import ComponentsAssociation from "../../_components/associations/ComponentsAssociation";
import {
  renderField,
  RawMaterialTypeSelectField,
  ColorSelectField
} from "../../_components/fields";
import {
  CurrencyFormatter,
  QuantityFormatter,
  DecimalNormalizer
} from "../../_services/field_formatters";
import { FormButtonsContainer } from "../../_layouts";

class FormTek extends Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    error: PropTypes.string
  };

  render() {
    const {
      handleSubmit,
      pristine,
      reset,
      submitting,
      handleDelete,
      extraButtons
    } = this.props;

    return (
      <Form layout="vertical" onSubmit={handleSubmit}>
        <Card title="Informazioni primarie">
          <Row>
            <Col xs={24}>
              <Field
                component={renderField}
                name="name"
                label="Nome"
                type="text"
                placeholder="Materia Prima"
                required={true}
              />
            </Col>
          </Row>
          <Row gutter={16}>
            <Col sm={12}>
              <Field
                component={RawMaterialTypeSelectField}
                name="type"
                label="Tipo"
                placeholder=""
              />
            </Col>
            <Col sm={12}>
              <Field
                component={ColorSelectField}
                name="color"
                label="Colore"
                placeholder=""
              />
            </Col>
          </Row>
          <Row gutter={16}>
            <Col sm={12}>
              <Field
                component={renderField}
                name="costPerKg"
                label="Costo al kg"
                type="text"
                placeholder="10,1234"
                format={CurrencyFormatter}
                normalize={DecimalNormalizer}
                required={true}
              />
            </Col>
            <Col sm={12}>
              <Field
                component={renderField}
                name="quantity"
                label="Quantità(in grammi)"
                type="text"
                placeholder="1,10"
                format={QuantityFormatter}
                normalize={DecimalNormalizer}
                required={true}
              />
            </Col>
          </Row>
        </Card>

        <Card title="Composizione interna">
          <FieldArray
            rerenderOnEveryChange={true}
            component={ComponentsAssociation}
            name="componentsPercentage"
          />
        </Card>
        <FormButtonsContainer
          extraButtons={extraButtons}
          disabled={pristine || submitting}
          handleDelete={handleDelete}
          reset={reset}
        />
      </Form>
    );
  }
}

export default reduxForm({
  form: "rawmaterial",
  enableReinitialize: true,
  keepDirtyOnReinitialize: true
})(FormTek);
