import React, { Component } from "react";
import { Input } from "antd";
import {
  DecimalNormalizer,
  CurrencyFormatter,
  QuantityFormatter
} from "../_services/field_formatters";

export class CurrencyInput extends Component {
  focus() {
    this.input.focus();
  }

  render() {
    const { value, onChange, onPressEnter, onBlur } = this.props;
    return (
      <Input
        ref={input => {
          this.input = input;
        }}
        onPressEnter={onPressEnter}
        onBlur={onBlur}
        onChange={event => {
          onChange(DecimalNormalizer(event.target.value));
        }}
        value={CurrencyFormatter(value)}
      />
    );
  }
}

export class QuantityInput extends Component {
  focus() {
    this.input.focus();
  }

  render() {
    const { value, onChange, onPressEnter, onBlur } = this.props;
    return (
      <Input
        ref={input => {
          this.input = input;
        }}
        onPressEnter={onPressEnter}
        onBlur={onBlur}
        onChange={event => {
          onChange(DecimalNormalizer(event.target.value));
        }}
        value={QuantityFormatter(value)}
      />
    );
  }
}
