import React, { Component } from "react";
import { ModuleView } from "../../_layouts";
import { Typography } from "antd";
const { Title, Paragraph } = Typography;

class Dashboard extends Component {
  render() {
    return (
      <ModuleView title="" subTitle="">
        <Typography>
          <Title level={3}>
            Benvenuto nel gestionale di Manifattura Bernina.
          </Title>
          <Paragraph>
            Seleziona un modulo dal menù a sinistra per continuare.
          </Paragraph>
        </Typography>
      </ModuleView>
    );
  }
}

export default Dashboard;
