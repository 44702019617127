const translations = {
  name: "Nome",
  description: "Descrizione",
  pieces: "Pezzi",
  color: "Colore",
  semifinished: "Semilavorati",
  quantity: "Quantità",
  accessories: "Accessori",
  variants: "Varianti"
};

export const translate = key => {
  if (key in translations) {
    return translations[key];
  }
  return key;
};
