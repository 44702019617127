import React, { Component } from "react";
import Form from "./Form";
import requireAuthentication from "../../_hoc/requireAuthentication";
import { connect } from "react-redux";
import { create, reset } from "../../actions/rawmaterialtype/create";
import CreateModalType from "../../_components/modal/CreateModalType";

class CreateModal extends Component {
  render() {
    const {
      item,
      visible,
      loading,
      error,
      onCreate,
      onCancel,
      create,
      reset
    } = this.props;
    return (
      <CreateModalType
        title="Creazione nuova tipologia di materia prima"
        visible={visible}
        Form={Form}
        error={error}
        onCreate={onCreate}
        onCancel={onCancel}
        loading={loading}
        item={item}
        create={create}
        reset={reset}
      />
    );
  }
}

const mapStateToProps = state => {
  const { created, error, loading } = state.rawmaterialtype.create;
  return { created, error, loading };
};

const mapDispatchToProps = dispatch => ({
  create: values => dispatch(create(values)),
  reset: () => dispatch(reset())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(requireAuthentication(CreateModal));
