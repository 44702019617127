import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { list, reset } from "../../actions/accessory/list";
import { TableClientSide } from "webtek-components";
import { ListMenu, ModuleView } from "../../_layouts";
import {
  CurrencyFormatter,
  QuantityFormatter
} from "../../_services/field_formatters";
import { update } from "../../actions/accessory/update";
import {
  EditableFormRow,
  EditableCell
} from "../../_components/table/EditableTable";
import { QuantityInput, CurrencyInput } from "../../input";
import { del } from "../../actions/accessory/delete";
import { Tooltip, Progress, Divider, Icon, Popconfirm } from "antd";

class List extends Component {
  static propTypes = {
    retrieved: PropTypes.object,
    loading: PropTypes.bool.isRequired,
    error: PropTypes.string,
    eventSource: PropTypes.instanceOf(EventSource),
    deletedItem: PropTypes.object,
    list: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired
  };

  handleSave = values => {
    const { update } = this.props;
    update(values, values).then(this.reloadValues);
    // console.log("handleSave", values);
  };

  reloadValues = () => {
    this.props.list(
      this.props.match.params.page &&
        decodeURIComponent(this.props.match.params.page)
    );
  };

  componentDidMount() {
    this.props.list(
      this.props.match.params.page &&
        decodeURIComponent(this.props.match.params.page)
    );
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.match.params.page !== nextProps.match.params.page)
      nextProps.list(
        nextProps.match.params.page &&
          decodeURIComponent(nextProps.match.params.page)
      );
  }

  componentWillUnmount() {
    this.props.reset(this.props.eventSource);
  }

  expandedRowRender = ({ products }) => {
    var productLinks = products.map(product => {
      return (
        <div key={product.id}>
          <Link to={`../products/edit/${encodeURIComponent(product["@id"])}`}>
            {product.name}
          </Link>
        </div>
      );
    });
    return <div>Prodotti associati {productLinks}</div>;
  };

  render() {
    const { loading, error, updateLoading } = this.props;
    const components = {
      body: {
        row: EditableFormRow,
        cell: EditableCell
      }
    };
    return (
      <ModuleView
        type="list"
        title="Elenco Accessori"
        subTitle="Visualizza gli accessori inseriti"
        loading={loading || updateLoading}
        notifications={this.retrieveNotifications()}
        error={error}
        errors={this.retrieveErrors()}
      >
        <ListMenu />
        <TableClientSide
          components={components}
          rowClassName={() => "editable-row"}
          dataSource={this.retrieveDataSource()}
          columns={this.retrieveColumns()}
          scroll={{ x: 1100 }}
          onRowDelete={this.deleteEntity}
          // expandedRowRender={this.expandedRowRender}
        />
      </ModuleView>
    );
  }

  deleteEntity = entityValues => {
    this.props.del(entityValues).then(this.reload);
  };
  reload = () => {
    this.props.list(
      this.props.match.params.page &&
        decodeURIComponent(this.props.match.params.page)
    );
  };

  retrieveErrors = () => {
    var errors = [];
    if (this.props.updateError) {
      errors.push({
        message: "Errore durante l'aggiornamento",
        description: this.props.updateError
      });
    }
    if (this.props.deleteError) {
      errors.push({
        message: "Errore durante l'eliminazione",
        description: this.props.deleteError
      });
    }
    return errors;
  };

  retrieveNotifications = () => {
    var notifications = [];
    if (this.props.created) {
      notifications.push({
        message: this.props.created["@id"] + " creato."
      });
    }
    if (this.props.updated) {
      notifications.push({
        message: this.props.updated["@id"] + " aggiornato."
      });
    }
    if (this.props.deletedItem) {
      notifications.push({
        message: this.props.deletedItem["@id"] + " eliminato."
      });
    }
    return notifications;
  };

  retrieveColumns() {
    return [
      {
        title: "id",
        key: "id",
        dataIndex: "id"
      },
      {
        title: "Nome",
        key: "name",
        dataIndex: "name",
        editable: true,
        onCell: record => ({
          record,
          editable: true,
          dataIndex: "name",
          title: "Nome",
          handleSave: this.handleSave
        })
      },
      {
        title: "Colore",
        key: "colorName",
        dataIndex: "colorName"
      },
      {
        title: "Unità di misura",
        key: "unitOfMeasureName",
        dataIndex: "unitOfMeasureName"
      },
      {
        title: "Quantità",
        key: "quantity",
        dataIndex: "quantity",
        sorter: {
          type: "numeric"
        },
        editable: true,
        onCell: record => ({
          record,
          editable: true,
          dataIndex: "quantity",
          title: "Quantità",
          handleSave: this.handleSave,
          editComponent: QuantityInput
        }),
        render: (value, record) => {
          return (
            QuantityFormatter(value) +
            " " +
            (record.unitOfMeasure ? record.unitOfMeasure.symbol : "")
          );
        }
      },
      {
        title: "Costo per udm",
        key: "costPerUnit",
        dataIndex: "costPerUnit",
        render: CurrencyFormatter,
        sorter: {
          type: "numeric"
        },
        editable: true,
        onCell: record => ({
          record,
          editable: true,
          dataIndex: "costPerUnit",
          title: "Costo per udm",
          handleSave: this.handleSave,
          editComponent: CurrencyInput
        })
      },
      {
        title: "Utilizzo budget",
        key: "budgetUsage",
        dataIndex: "budgetUsage",
        filter: null,
        sorter: (a, b) => {
          return a.budgetUsage.percentage > b.budgetUsage.percentage;
        },
        render: value => {
          return (
            <Tooltip
              title={
                value.used +
                "/" +
                value.total +
                ", rimanente: " +
                value.remaining
              }
              style={{ width: 100 }}
            >
              <Progress
                percent={value.percentage}
                status={value.percentage >= 100 ? "exception" : "normal"}
                size="small"
              />
            </Tooltip>
          );
        }
      },
      {
        title: "Azioni",
        key: "action",
        fixed: "right",
        filter: null,
        sorter: null,
        width: 125,
        render: (text, record) => (
          <div className="table__actionsMenu">
            <Divider type="vertical" />
            <Tooltip title="Modifica la materia prima">
              <Link to={`edit/${encodeURIComponent(record["@id"])}`}>
                <Icon type="edit" />
              </Link>
            </Tooltip>
            <Divider type="vertical" />
            <Tooltip title="Visualizza i movimenti di magazzino">
              <Link to={`stock_movements/${encodeURIComponent(record["@id"])}`}>
                <Icon type="stock" />
              </Link>
            </Tooltip>
            <Divider type="vertical" />
            <Tooltip title="Elimina la materia prima">
              <Popconfirm
                title="Sei sicuro di voler eliminare questo elemento"
                onConfirm={() => {
                  this.deleteEntity(record);
                }}
                okText="Si"
                cancelText="No"
              >
                <Icon type="delete" />
              </Popconfirm>
            </Tooltip>
          </div>
        )
      }
      // {
      //   title: "Prodotti",
      //   key: "products",
      //   dataIndex: "products",
      //   render: records => {
      //     var links = records.map((product, index) => {
      //       return (
      //         <span key={product.id}>
      //           <Link
      //             to={`../products/edit/${encodeURIComponent(product["@id"])}`}
      //           >
      //             {product.name}
      //           </Link>
      //           {index !== records.length - 1 ? ", " : ""}
      //         </span>
      //       );
      //     });
      //     return links;
      //   }
      // }
    ];
  }

  retrieveDataSource() {
    if (this.props.retrieved && this.props.retrieved["hydra:member"]) {
      return this.props.retrieved["hydra:member"].map(item => {
        return {
          key: item["@id"],
          "@id": item["@id"],
          id: item["id"],
          name: item["name"],
          color: item["color"],
          colorName: item["color"] ? item["color"].name : null,
          unitOfMeasure: item["unitOfMeasure"],
          unitOfMeasureName: item["unitOfMeasure"]
            ? item["unitOfMeasure"].name
            : null,
          quantity: item["quantity"],
          costPerUnit: item["costPerUnit"],
          // products: item["products"],
          budgetUsage: item["budgetUsage"]
        };
      });
    }
    return [];
  }

  renderLinks = (type, items) => {
    if (Array.isArray(items)) {
      return items.map((item, i) => (
        <div key={i}>{this.renderLinks(type, item)}</div>
      ));
    }

    return (
      <Link to={`../${type}/show/${encodeURIComponent(items)}`}>{items}</Link>
    );
  };
}

const mapStateToProps = state => {
  const { retrieved, loading, error, eventSource } = state.accessory.list;
  const deletedItem = state.accessory.del.deleted;

  return {
    retrieved,
    loading,
    error,
    eventSource,
    deletedItem,
    deleteError: state.accessory.del.error,
    deleteLoading: state.accessory.del.loading,
    updateError: state.accessory.update.updateError,
    updateLoading: state.accessory.update.updateLoading,
    updated: state.accessory.update.updated
  };
};

const mapDispatchToProps = dispatch => ({
  list: page => dispatch(list(page)),
  update: (item, values) => dispatch(update(item, values)),
  del: item => dispatch(del(item)),

  reset: eventSource => dispatch(reset(eventSource))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(List);
