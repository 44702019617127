import React, { Component } from "react";
import { Button } from "antd";
// import "./style.less";

export default class FormButtonsContainer extends Component {
  render() {
    const { extraButtons, handleDelete, reset, disabled } = this.props;
    return (
      <div className="form__buttons_container">
        <div className="form__buttons_container__content">
          {extraButtons}
          <Button disabled={disabled} onClick={reset}>
            Cancella valori
          </Button>
          <Button
            type="primary"
            disabled={disabled}
            htmlType="submit"
            onClick={() => {
              window.scrollTo(0, 0);
            }}
          >
            Salva
          </Button>
          {handleDelete && (
            <Button type="danger" icon="delete" onClick={handleDelete}>
              Elimina
            </Button>
          )}
        </div>
      </div>
    );
  }
}
