import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import PropTypes from "prop-types";
import Form from "./Form";
import { create, reset } from "../../actions/budget/create";
import requireAuthentication from "../../_hoc/requireAuthentication";
import { Button } from "antd";
import { ModuleView } from "../../_layouts";

class Create extends Component {
  static propTypes = {
    error: PropTypes.string,
    loading: PropTypes.bool.isRequired,
    created: PropTypes.object,
    create: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired
  };

  componentWillUnmount() {
    this.props.reset();
  }

  render() {
    const { loading, error, create, item, created } = this.props;
    if (created)
      return (
        <Redirect
          to={`edit/${encodeURIComponent(this.props.created["@id"])}`}
        />
      );

    return (
      <ModuleView
        title="Nuovo Budget"
        subTitle="Creazione di un nuovo Budget"
        onBack={() => window.history.back()}
        loading={loading}
        error={error}
      >
        <Form
          onSubmit={create}
          values={item}
          extraButtons={
            <Button type="danger" href=".">
              Annulla
            </Button>
          }
        />
      </ModuleView>
    );
  }
}

const mapStateToProps = state => {
  const { created, error, loading } = state.budget.create;
  return { created, error, loading };
};

const mapDispatchToProps = dispatch => ({
  create: values => dispatch(create(values)),
  reset: () => dispatch(reset())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(requireAuthentication(Create));
