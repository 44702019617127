import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { list, reset } from "../../actions/budget/list";
import { TableClientSide } from "webtek-components";
import ListMenu from "../../_layouts/ListMenu";
import ModuleView from "../../_layouts/ModuleView";

class List extends Component {
  static propTypes = {
    retrieved: PropTypes.object,
    loading: PropTypes.bool.isRequired,
    error: PropTypes.string,
    eventSource: PropTypes.instanceOf(EventSource),
    deletedItem: PropTypes.object,
    list: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired
  };

  componentDidMount() {
    this.props.list(
      this.props.match.params.page &&
        decodeURIComponent(this.props.match.params.page)
    );
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.match.params.page !== nextProps.match.params.page)
      nextProps.list(
        nextProps.match.params.page &&
          decodeURIComponent(nextProps.match.params.page)
      );
  }

  componentWillUnmount() {
    this.props.reset(this.props.eventSource);
  }

  retrieveNotifications = () => {
    var notifications = [];
    if (this.props.deletedItem) {
      notifications.push({
        message: this.props.deletedItem["@id"] + " eliminato."
      });
    }
    return notifications;
  };

  retrieveColumns() {
    return [
      {
        title: "id",
        key: "id",
        dataIndex: "id"
      },
      {
        title: "Anno",
        key: "year",
        dataIndex: "year",
        sorter: {
          type: "numeric"
        },
        filter: null
      }
    ];
  }

  retrieveDataSource() {
    if (this.props.retrieved && this.props.retrieved["hydra:member"]) {
      return this.props.retrieved["hydra:member"].map(item => {
        return {
          key: item["@id"],
          "@id": item["@id"],
          id: item["@id"],
          year: item["year"]
        };
      });
    }
    return [];
  }

  render() {
    const { loading, error } = this.props;
    return (
      <ModuleView
        title="Elenco Budget"
        subTitle="Visualizza i Budget inseriti"
        loading={loading}
        notifications={this.retrieveNotifications()}
        error={error}
      >
        <ListMenu />
        <TableClientSide
          dataSource={this.retrieveDataSource()}
          columns={this.retrieveColumns()}
        />
      </ModuleView>
    );
  }

  // pagination() {
  //   const view = this.props.retrieved && this.props.retrieved['hydra:view'];
  //   if (!view) return;

  //   const {
  //     'hydra:first': first,
  //     'hydra:previous': previous,
  //     'hydra:next': next,
  //     'hydra:last': last
  //   } = view;

  //   return (
  //     <nav aria-label="Page navigation">
  //       <Link
  //         to="."
  //         className={`btn btn-primary${previous ? '' : ' disabled'}`}
  //       >
  //         <span aria-hidden="true">&lArr;</span> First
  //       </Link>
  //       <Link
  //         to={
  //           !previous || previous === first ? '.' : encodeURIComponent(previous)
  //         }
  //         className={`btn btn-primary${previous ? '' : ' disabled'}`}
  //       >
  //         <span aria-hidden="true">&larr;</span> Previous
  //       </Link>
  //       <Link
  //         to={next ? encodeURIComponent(next) : '#'}
  //         className={`btn btn-primary${next ? '' : ' disabled'}`}
  //       >
  //         Next <span aria-hidden="true">&rarr;</span>
  //       </Link>
  //       <Link
  //         to={last ? encodeURIComponent(last) : '#'}
  //         className={`btn btn-primary${next ? '' : ' disabled'}`}
  //       >
  //         Last <span aria-hidden="true">&rArr;</span>
  //       </Link>
  //     </nav>
  //   );
  // }

  renderLinks = (type, items) => {
    if (Array.isArray(items)) {
      return items.map((item, i) => (
        <div key={i}>{this.renderLinks(type, item)}</div>
      ));
    }

    return (
      <Link to={`../${type}/show/${encodeURIComponent(items)}`}>{items}</Link>
    );
  };
}

const mapStateToProps = state => {
  const { retrieved, loading, error, eventSource } = state.budget.list;
  const deletedItem = state.budget.del.deleted;
  return { retrieved, loading, error, eventSource, deletedItem };
};

const mapDispatchToProps = dispatch => ({
  list: page => dispatch(list(page)),
  reset: eventSource => dispatch(reset(eventSource))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(List);
