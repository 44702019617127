const translations = {
  name: "Nome",
  hexCode: "Codice",
  type: "Tipo",
  color: "Colore",
  costPerKg: "Costo al kg",
  quantity: "Quantità",
  component: "Componente"
};

export const translate = key => {
  if (key in translations) {
    return translations[key];
  }
  return key;
};
