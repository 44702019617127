import React, { Component } from "react";
import { Form, Modal, Input, Table, Row, Col } from "antd";
import { connect } from "react-redux";
import {
  CurrencyFormatter,
  QuantityFormatter,
  DecimalNormalizer
} from "../../_services/field_formatters";

const EditableContext = React.createContext();

const EditableRow = ({ form, index, ...props }) => (
  <EditableContext.Provider value={form}>
    <tr {...props} />
  </EditableContext.Provider>
);

const EditableFormRow = Form.create()(EditableRow);

class EditableCell extends React.Component {
  state = {
    editing: false
  };

  toggleEdit = () => {
    const editing = !this.state.editing;
    console.log(this);
    this.setState({ editing }, () => {
      if (editing) {
        this.input.focus();
      }
    });
  };

  save = e => {
    const { record, handleSave } = this.props;
    this.form.validateFields((error, values) => {
      if (error && error[e.currentTarget.id]) {
        return;
      }
      this.toggleEdit();
      values.quantity = values.quantity.toString();
      handleSave({ ...record, ...values }, this.props.indice);
    });
  };

  render() {
    const { editing } = this.state;
    const {
      editable,
      dataIndex,
      title,
      record,
      index,
      handleSave,
      ...restProps
    } = this.props;
    return (
      <td {...restProps}>
        {editable ? (
          <EditableContext.Consumer>
            {form => {
              this.form = form;
              return editing ? (
                <Form.Item style={{ margin: 0 }}>
                  {form.getFieldDecorator(dataIndex, {
                    rules: [
                      {
                        required: true,
                        message:
                          "Devi inserire un valore numerico maggiore o uguale a 0",
                        min: 0,
                        type: "string"
                      }
                    ],
                    initialValue: record[dataIndex]
                  })(
                    <QuantityInput
                      ref={node => (this.input = node)}
                      onPressEnter={this.save}
                      onBlur={this.save}
                    />
                  )}
                </Form.Item>
              ) : (
                <div
                  className="editable-cell-value-wrap"
                  style={{ paddingRight: 24 }}
                  onClick={this.toggleEdit}
                >
                  {restProps.children}
                </div>
              );
            }}
          </EditableContext.Consumer>
        ) : (
          restProps.children
        )}
      </td>
    );
  }
}

class EditableAssociationTable extends React.Component {
  handleSave = ({ quantity }, index) => {
    var formData = this.props.value;
    formData[index].quantity = quantity;
    console.log(formData);
    if (formData[index].semifinished)
      formData[index].costRelative = (
        quantity *
        (formData[index].costPerKg / 1000)
      ).toString();
    else if (formData[index].accessory)
      formData[index].costRelative = (
        quantity * formData[index].costPerUnit
      ).toString();
    this.props.onChange(formData);
  };
  render() {
    const components = {
      body: {
        row: EditableFormRow,
        cell: EditableCell
      }
    };

    return (
      <Table
        size="small"
        components={components}
        rowClassName={() => "editable-row"}
        columns={this.props.columns.map(values => {
          if (values.makeEditable) {
            values.onCell = (record, index) => ({
              record,
              editable: true,
              indice: index,
              dataIndex: "quantity",
              title: "Quantità",
              handleSave: this.handleSave
            });
          }
          return values;
        })}
        dataSource={this.props.value}
      />
    );
  }
}

class CurrencyInput extends Component {
  render() {
    const { value, onChange } = this.props;
    return (
      <Input
        onChange={event => {
          onChange(DecimalNormalizer(event.target.value));
        }}
        value={CurrencyFormatter(value)}
      />
    );
  }
}

class QuantityInput extends Component {
  focus() {
    this.input.focus();
  }

  render() {
    const { value, onChange, onPressEnter, onBlur } = this.props;
    return (
      <Input
        ref={input => {
          this.input = input;
        }}
        onPressEnter={onPressEnter}
        onBlur={onBlur}
        onChange={event => {
          onChange(DecimalNormalizer(event.target.value));
        }}
        value={QuantityFormatter(value)}
      />
    );
  }
}

class ProductVariantForm extends Component {
  render() {
    const {
      visible,
      onCancel,
      onCreate,
      form,
      semifinished = [],
      accessories = [],
      values = null
    } = this.props;
    const { getFieldDecorator } = form;
    return (
      <Modal
        title="Aggiunta Variante"
        okText={values ? "Modifica" : "Aggiungi"}
        style={{ top: 20 }}
        width="70%"
        visible={visible}
        onOk={onCreate}
        onCancel={onCancel}
      >
        <Form layout="vertical">
          <Row gutter={16}>
            <Col sm={12}>
              <Form.Item label="Codice">
                {getFieldDecorator("code", {
                  rules: [
                    {
                      required: true,
                      message: "Questo valore è richiesto",
                      type: "string"
                    }
                  ]
                })(<Input />)}
              </Form.Item>
            </Col>
            <Col sm={12}>
              <Form.Item label="Nome">
                {getFieldDecorator("name", {
                  rules: [
                    {
                      required: true,
                      message: "Questo valore è richiesto",
                      type: "string"
                    }
                  ]
                })(<Input />)}
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col sm={12}>
              <Form.Item label="Codice a barre">
                {getFieldDecorator("barcode")(<Input />)}
              </Form.Item>
            </Col>
            <Col sm={12}>
              <Form.Item label="Prezzo">
                {getFieldDecorator("price", {
                  rules: [
                    {
                      required: true,
                      message: "Questo valore è richiesto"
                    }
                  ]
                })(<CurrencyInput />)}
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col sm={12}>
              <Form.Item label="Quantità">
                {getFieldDecorator("quantity", {
                  rules: [
                    {
                      required: true,
                      message: "Questo valore è richiesto"
                    }
                  ]
                })(<QuantityInput />)}
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col sm={12}>
              <Form.Item label="Semilavorati">
                {getFieldDecorator("semifinishedsQuantities", {
                  initialValue: semifinished.map(value => ({
                    key: value,
                    label: this.props.semifinishedList[value]
                      ? this.props.semifinishedList[value].name
                      : value,
                    costPerKg: this.props.semifinishedList[value]
                      ? this.props.semifinishedList[value].cost
                      : null,
                    semifinished: value,
                    quantity: "0"
                  }))
                })(
                  <EditableAssociationTable
                    columns={[
                      {
                        title: "Semilavorato",
                        key: "label",
                        dataIndex: "label",
                        sorter: {
                          type: "alphabetical"
                        },
                        filter: {
                          type: "regexp"
                        }
                      },
                      {
                        title: "Costo relativo",
                        key: "costRelative",
                        dataIndex: "costRelative",
                        sorter: {
                          type: "alphabetical"
                        },
                        filter: {
                          type: "regexp"
                        },
                        render: CurrencyFormatter
                      },
                      {
                        title: "Quantità",
                        key: "quantity",
                        dataIndex: "quantity",
                        makeEditable: true,
                        sorter: {
                          type: "alphabetical"
                        },
                        filter: {
                          type: "regexp"
                        },
                        render: QuantityFormatter
                      }
                    ]}
                  />
                )}
              </Form.Item>
            </Col>
            <Col sm={12}>
              <Form.Item label="Accessori">
                {getFieldDecorator("accessoriesQuantities", {
                  initialValue: accessories.map(value => ({
                    key: value,
                    label: this.props.accessoryList[value]
                      ? this.props.accessoryList[value].name
                      : value,
                    costPerUnit: this.props.accessoryList[value]
                      ? this.props.accessoryList[value].cost
                      : null,
                    accessory: value,
                    quantity: "0"
                  }))
                })(
                  <EditableAssociationTable
                    columns={[
                      {
                        title: "Accessorio",
                        key: "label",
                        dataIndex: "label",
                        sorter: {
                          type: "alphabetical"
                        },
                        filter: {
                          type: "regexp"
                        }
                      },
                      {
                        title: "Costo relativo",
                        key: "costRelative",
                        dataIndex: "costRelative",
                        sorter: {
                          type: "alphabetical"
                        },
                        filter: {
                          type: "regexp"
                        },
                        render: CurrencyFormatter
                      },
                      {
                        title: "Quantità",
                        key: "quantity",
                        dataIndex: "quantity",
                        makeEditable: true,
                        sorter: {
                          type: "alphabetical"
                        },
                        filter: {
                          type: "regexp"
                        },
                        render: QuantityFormatter
                      }
                    ]}
                  />
                )}
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    );
  }
}

const mapPropsToFields = ({ values, semifinishedList, accessoryList }) => {
  if (values) {
    var fields = {};
    for (var key in values) {
      var value = values[key];
      if (key === "semifinishedsQuantities") {
        value = value.map(records => {
          return {
            ...records,
            semifinished: records.semifinished,
            costPerKg: semifinishedList[records.semifinished]["cost"],
            label:
              semifinishedList[records.semifinished]["name"] ||
              records.semifinished,
            key: records.semifinished
          };
        });
      }
      if (key === "accessoriesQuantities") {
        value = value.map(records => {
          return {
            ...records,
            costPerUnit: accessoryList[records.accessory]["cost"],
            accessory: records.accessory,
            label:
              accessoryList[records.accessory]["name"] || records.accessory,
            key: records.accessory
          };
        });
      }
      fields[key] = Form.createFormField({
        value: value
      });
    }
    return fields;
  }
};

const mapStateToProps = state => {
  const semifinishedRetrieved = state.semifinished.list.retrieved;
  var semifinishedList = {};
  if (semifinishedRetrieved) {
    semifinishedRetrieved["hydra:member"].forEach(values => {
      semifinishedList[values["@id"]] = {
        name: values["name"],
        cost: values["costPerKg"]
      };
    });
  }
  const accessoriesRetrieved = state.accessory.list.retrieved;
  var accessoryList = {};
  if (accessoriesRetrieved) {
    accessoriesRetrieved["hydra:member"].forEach(values => {
      accessoryList[values["@id"]] = {
        name: values["name"],
        cost: values["costPerUnit"]
      };
    });
  }
  return { semifinishedList: semifinishedList, accessoryList: accessoryList };
};

// export default connect(mapStateToProps)(ProductVariantAssociation);

export default connect(mapStateToProps)(
  Form.create({
    name: "product_variant_form",
    mapPropsToFields: mapPropsToFields
  })(ProductVariantForm)
);
