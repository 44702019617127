import React from "react";
import { Route } from "react-router-dom";
import {
  List,
  Create,
  Update,
  Show,
  ShowStockMovements
} from "../components/rawmaterial/";

export default [
  <Route path="/raw_materials/create" component={Create} exact key="create" />,
  <Route
    path="/raw_materials/edit/:id"
    component={Update}
    exact
    key="update"
  />,
  <Route
    path="/raw_materials/stock_movements/:id"
    component={ShowStockMovements}
    exact
    key="showStockMovement"
  />,
  <Route path="/raw_materials/show/:id" component={Show} exact key="show" />,
  <Route path="/raw_materials/" component={List} exact strict key="list" />,
  <Route path="/raw_materials/:page" component={List} exact strict key="page" />
];
