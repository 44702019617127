import "antd/lib/menu/style";
import _Menu from "antd/lib/menu";
import React from "react";
import Item from "./Item";
import SubMenu from "./SubMenu";

var Main = function Main(_ref) {
  var _ref$openKeys = _ref.openKeys,
      openKeys = _ref$openKeys === undefined ? [] : _ref$openKeys,
      _ref$selectedKeys = _ref.selectedKeys,
      selectedKeys = _ref$selectedKeys === undefined ? [] : _ref$selectedKeys,
      onTitleClick = _ref.onTitleClick,
      _ref$modules = _ref.modules,
      modules = _ref$modules === undefined ? [] : _ref$modules;
  return React.createElement(_Menu, {
    theme: "dark",
    mode: "inline",
    openKeys: openKeys,
    selectedKeys: selectedKeys
  }, modules.map(function (module) {
    if (typeof module.submodules !== "undefined" && module.submodules.length) {
      var submodules = module.submodules.map(function (submodule) {
        return React.createElement(Item, {
          key: submodule.route,
          route: submodule.route,
          icon: submodule.icon,
          label: submodule.label
        });
      });
      return React.createElement(SubMenu, {
        key: module.route + "_main",
        onTitleClick: onTitleClick,
        icon: module.icon,
        label: module.label
      }, submodules);
    } else {
      return React.createElement(Item, {
        key: module.route,
        route: module.route,
        icon: module.icon,
        label: module.label
      });
    }
  }));
};

export default {
  Main: Main,
  Item: Item,
  SubMenu: SubMenu
};