import { connect } from "react-redux";
import React, { Component } from "react";
import { Select } from "antd";
import { list, reset } from "../../actions/color/list";

class ColorSelect extends Component {
  componentDidMount() {
    this.props.list();
  }
  componentWillUnmount() {
    this.props.reset(this.props.eventSource);
  }

  render() {
    var { retrieved, value, ...rest } = this.props;
    if (typeof value === "object") {
      value = value["@id"];
    }
    const options = retrieved
      ? retrieved["hydra:member"].map(values => (
          <Select.Option key={values["@id"]} value={values["@id"]}>
            {values["name"]}
          </Select.Option>
        ))
      : null;
    var effectiveValue = {};
    if (value) {
      effectiveValue = { value: value };
    }
    if (options)
      return (
        <Select
          showSearch
          {...effectiveValue}
          filterOption={(input, option) =>
            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >=
            0
          }
          {...rest}
          placeholder="Scegli un colore"
        >
          {options}
        </Select>
      );

    return "";
  }
}

const mapStateToProps = state => {
  const { retrieved, loading, error, eventSource } = state.color.list;
  return { retrieved, loading, error, eventSource };
};

const mapDispatchToProps = dispatch => ({
  list: page => dispatch(list(page)),
  reset: eventSource => dispatch(reset(eventSource))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ColorSelect);
