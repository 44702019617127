import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { list, reset } from "../../actions/rawmaterialtype/list";
import { TableClientSide } from "webtek-components";
import { ListMenu, ModuleView } from "../../_layouts";
import {
  EditableFormRow,
  EditableCell
} from "../../_components/table/EditableTable";
import UpdateModal from "./UpdateModal";
import CreateModal from "./CreateModal";
import { del } from "../../actions/rawmaterialtype/delete";
import { update } from "../../actions/rawmaterialtype/update";

class List extends Component {
  static propTypes = {
    retrieved: PropTypes.object,
    loading: PropTypes.bool.isRequired,
    error: PropTypes.string,
    eventSource: PropTypes.instanceOf(EventSource),
    deletedItem: PropTypes.object,
    list: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired
  };
  state = { onCreate: false, onEdit: null };

  componentDidMount() {
    this.props.list(
      this.props.match.params.page &&
        decodeURIComponent(this.props.match.params.page)
    );
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.match.params.page !== nextProps.match.params.page)
      nextProps.list(
        nextProps.match.params.page &&
          decodeURIComponent(nextProps.match.params.page)
      );
  }

  componentWillUnmount() {
    this.props.reset(this.props.eventSource);
  }

  render() {
    const { loading, error, deleteLoading, updateLoading } = this.props;
    return (
      <ModuleView
        type="list"
        title="Elenco Tipologie di materia prime"
        subTitle="Visualizza i tipi di materia prima inseriti"
        loading={loading || deleteLoading || updateLoading}
        notifications={this.retrieveNotifications()}
        error={error}
        errors={this.retrieveErrors()}
      >
        <ListMenu onClick={this.showModal} />
        <CreateModal
          onCreate={this.reload}
          visible={this.state.onCreate}
          onCancel={this.hideModal}
        />
        {this.state.onEdit ? (
          <UpdateModal
            onUpdate={this.reload}
            visible={true}
            iri={this.state.onEdit}
            onCancel={this.hideModal}
          />
        ) : null}

        <TableClientSide
          components={{
            body: {
              row: EditableFormRow,
              cell: EditableCell
            }
          }}
          rowClassName={() => "editable-row"}
          dataSource={this.retrieveDataSource()}
          columns={this.retrieveColumns()}
          onRowEdit={this.editEntity}
          onRowDelete={this.deleteEntity}
          scroll={{ x: 600 }}
        />
      </ModuleView>
    );
  }
  showModal = () => {
    this.setState({
      onCreate: true
    });
  };

  hideModal = () => {
    this.setState({
      onCreate: false,
      onEdit: null
    });
  };

  retrieveColumns() {
    return [
      {
        title: "Nome",
        key: "name",
        dataIndex: "name",
        editable: true,
        onCell: record => ({
          record,
          editable: true,
          dataIndex: "name",
          title: "Nome",
          handleSave: this.handleInCellSave
        })
      }
      // {
      //   title: "Materie prime",
      //   key: "rawMaterials",
      //   dataIndex: "rawMaterials",
      //   render: value => {
      //     return this.renderLinks("raw_materials", value);
      //   }
      // }
    ];
  }
  retrieveDataSource() {
    if (this.props.retrieved && this.props.retrieved["hydra:member"]) {
      return this.props.retrieved["hydra:member"].map(item => {
        return {
          key: item["@id"],
          "@id": item["@id"],
          id: item["id"],
          name: item["name"],
          rawMaterials: item["rawMaterials"]
        };
      });
    }
    return [];
  }

  retrieveErrors = () => {
    var errors = [];
    if (this.props.updateError) {
      errors.push({
        message: "Errore durante l'aggiornamento",
        description: this.props.updateError
      });
    }
    if (this.props.deleteError) {
      errors.push({
        message: "Errore durante l'eliminazione",
        description: this.props.deleteError
      });
    }
    return errors;
  };

  retrieveNotifications = () => {
    var notifications = [];
    if (this.props.created) {
      notifications.push({
        message: this.props.created["@id"] + " creato."
      });
    }
    if (this.props.updated) {
      notifications.push({
        message: this.props.updated["@id"] + " aggiornato."
      });
    }
    if (this.props.deletedItem) {
      notifications.push({
        message: this.props.deletedItem["@id"] + " eliminato."
      });
    }
    return notifications;
  };

  deleteEntity = entityValues => {
    this.props.del(entityValues).then(this.reload);
  };

  editEntity = entityValues => {
    this.setState({
      onEdit: entityValues["@id"]
    });
  };

  handleInCellSave = values => {
    const { update } = this.props;
    update(values, values).then(this.reload);
  };
  reload = () => {
    this.hideModal();
    this.props.list(
      this.props.match.params.page &&
        decodeURIComponent(this.props.match.params.page)
    );
  };

  renderLinks = (type, items) => {
    if (Array.isArray(items)) {
      return items.map((item, i) => (
        <div key={i}>{this.renderLinks(type, item)}</div>
      ));
    }

    return (
      <Link to={`../${type}/show/${encodeURIComponent(items)}`}>{items}</Link>
    );
  };
}

const mapStateToProps = state => {
  const { retrieved, loading, error, eventSource } = state.rawmaterialtype.list;
  const deletedItem = state.rawmaterialtype.del.deleted;
  return {
    retrieved,
    loading,
    error,
    eventSource,
    deletedItem,
    deleteError: state.rawmaterialtype.del.error,
    deleteLoading: state.rawmaterialtype.del.loading,
    updateError: state.rawmaterialtype.update.updateError,
    updateLoading: state.rawmaterialtype.update.updateLoading,
    updated: state.rawmaterialtype.update.updated,
    created: state.rawmaterialtype.create.created
  };
};

const mapDispatchToProps = dispatch => ({
  list: page => dispatch(list(page)),
  reset: eventSource => dispatch(reset(eventSource)),
  del: item => dispatch(del(item)),
  update: (item, values) => dispatch(update(item, values))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(List);
